/* eslint-disable react/jsx-pascal-case */

import './App.css'
import "antd/dist/antd.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import ReactGA from 'react-ga'
import { about } from './Pages/about/about'
import { services } from './Pages/services/services'
import { contact } from './Pages/contact/contact'
import { quote } from './Pages/quote/quote'
import { homepage } from './Pages/homepage/homepage'
import { privacyPolicy } from './Pages/privacy-policy/privacy-policy'
import { login } from './Pages/login/login'
import { dashboard } from './Pages/dashboard/dashboard'
import { parser } from './Pages/parser/parser'
import { Context_Username } from './Constants/contexts'
import { useState } from 'react'

//Google Analytics
const TRACKING_ID = "UA-202009452-1"
ReactGA.initialize(TRACKING_ID)
ReactGA.pageview(window.location.pathname + window.location.search)

function App() {

  const [username, setUsername] = useState<string>("")

  return (
    <Router>
        <div className="App">
          <Switch>
            <Context_Username.Provider value={[ username, setUsername ]}>

              {/* Informative */}
              <Route path="/" exact component={homepage}/>
              <Route path="/about" exact component={about}/>
              <Route path="/services" exact component={services}/>
              <Route path="/contact" exact component={contact}/>

              {/* Employees */}
              <Route path="/login" exact component={login}/>
              <Route path="/dashboard" exact component={dashboard}/>
              <Route path="/quote" exact component={quote}/>
              <Route path="/parser" exact component={parser}/>
              <Route path="/privacy-policy" exact component={privacyPolicy}/>

            </Context_Username.Provider>
          </Switch>
        </div>
    </Router>
  )
}

export default App
