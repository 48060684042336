import "./RegistrationTab.css"
import { useState } from "react"
import { 
    Button,
    TextField,
    Alert,
    Modal,
    Snackbar,
    Backdrop,
    Loader
} from "../../Constants/libraries"
import { formatInputs } from "../../Constants/functions"
import { RegistrationForm } from "../../Constants/types"

export const RegistrationTab = () => {

    //States
    const [registerCredentials, setRegisterCredentials] = useState<RegistrationForm>({
        username: "",
        password: "", 
        password_repeat: "",
        shortname: "",
        verification: "",
        verification_code: true,
        error: "",
        error_input:{
            username: "",
            password: "",
            password_repeat: "",
            shortname: "",
            global: ""
        }
    })

    //Indicators
    const [registerModal, setRegisterModal] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<boolean>(false)
    const [initiateRegistration, setInitiateRegistration] = useState<boolean>(false)

    const format = () => {
        let temp = {...registerCredentials}

        //Validate username
        temp.error_input.username = formatInputs(registerCredentials.username, "email")
        setRegisterCredentials(temp)

        //Validate passwords
        temp.error_input.password = formatInputs(registerCredentials.password, "password", "Password: ")
        temp.error_input.password_repeat = formatInputs(registerCredentials.password_repeat, "password", "Confirm Password: ")
        if (registerCredentials.password !== registerCredentials.password_repeat) {
            temp.error_input.password = "Passwords do not match"
            temp.error_input.password_repeat = "Passwords do not match"
        }
        setRegisterCredentials(temp)

        //Validate shortname
        temp.error_input.shortname = formatInputs(registerCredentials.shortname, "general", "Name: " )
        setRegisterCredentials(temp)
    }

    const register = async () => {

        //Format all inputs & display error messages
        format()
        let inputError = false
        const register_errors = Object.values(registerCredentials.error_input)
        for (let i = 0; i < register_errors.length; i++) {
            if (register_errors[i] !== "") {
                setRegisterCredentials({
                    ...registerCredentials,
                    error: "inputError"
                })
                inputError = true
                break
            }
        }

        //No errors so create user
        if (!inputError) {
            setRegisterCredentials({
                ...registerCredentials,
                error: ""
            })
            const register = {
                username: registerCredentials.username,
                password: registerCredentials.password,
                shortname: registerCredentials.shortname,
            }

            //Register
            try {
                setInitiateRegistration(true)
                const user_uf = await fetch("https://aerotrendsaviationbackend.com/login/create_user", {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                    method: 'post',
                    body: JSON.stringify(register)
                })
                const user = await user_uf.json()
                if (user[0] === "DuplicateUser") { 
                    setRegisterCredentials({
                        ...registerCredentials,
                        error: "This email / username already exists."
                    })
                }
                else if (user[0] === "ValidCreateUser") setRegisterModal(true)
                else {
                    setRegisterCredentials({
                        ...registerCredentials,
                        error: "An unexpecter error occured. Please try again later."
                    })
                }
                setInitiateRegistration(false)
            }
            catch(e) {
                console.log("Register Error", e)
                setRegisterCredentials({
                    ...registerCredentials,
                    error: "An unexpecter error occured. Please try again later."
                })
                setInitiateRegistration(false)
            }
        }
    }

    const verifyVerificationCode = async () => {
        const verify = {
            username: registerCredentials.username,
            verification: registerCredentials.verification,
        }

        try {
            setInitiateRegistration(true)
            const verification_uf = await fetch("https://aerotrendsaviationbackend.com/login/verify_user", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                method: 'post',
                body: JSON.stringify(verify)
            })
            const verification = await verification_uf.json()

            if (verification[0] === "InvalidVerificationCode") {
                setRegisterCredentials({
                    ...registerCredentials,
                    verification_code: false
                })
            }
            else if (verification[0] === "ValidVerification") {
                setRegisterCredentials({
                    ...registerCredentials,
                    verification_code: true
                })
                setSnackbar(true)
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            }
            else {
                setRegisterModal(false)
                setRegisterCredentials({
                    ...registerCredentials,
                    error: "An unexpecter error occured. Please try again later."
                })
            }
            setInitiateRegistration(false)
        }    
        catch(e) {
            console.log("Verify Verification Code Error", e)
            setRegisterModal(false)
            setRegisterCredentials({
                ...registerCredentials,
                error: "An unexpecter error occured. Please try again later."
            })
            setInitiateRegistration(false)
        }
    }

    return (
        <div className="RegistrationTab">

            {/* Loader */}
            <Backdrop className="RegistrationTab__Backdrop" open={initiateRegistration} transitionDuration={1000}>
                <div className="RegistrationTab__Loader">
                    <Loader
                        type="Puff"
                        color="#508FCD"
                        height={100}
                        width={100}
                    />
                    <p>Registering</p>
                </div>
            </Backdrop>

            {/* Popup */}
            <Snackbar open={snackbar} autoHideDuration={3000} onClose={() => setSnackbar(false)}>
                <Alert onClose={() => setSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Verification successful. You will be redirected to log in again.
                </Alert>
            </Snackbar>

            {/* Modal */}
            <Modal
                title=""
                centered
                visible={registerModal}
                onCancel={() => setRegisterModal(false)}
                onOk={async () => await verifyVerificationCode()}
                closable
                width={1000}
            >
                <div className="RegistrationTab__Modal">
                    <p>Enter the verification code you received in your email:</p>
                    <TextField 
                        style={{width: "auto"}}
                        variant="filled"
                        label="Verification Code"
                        onChange = {(e) => setRegisterCredentials({
                            ...registerCredentials,
                            verification: e.target.value
                        })}
                        error={!registerCredentials.verification_code}
                        helperText={!registerCredentials.verification_code ? "Incorrect Verification Code" : ""}
                    /> 
                </div>
            </Modal>
            
            {/* Intro */}
            <div className="RegistrationTab__Title">
                <p>Register your new account</p>
                <p>An email verification is required after registering</p>
            </div>

            {/* Errors */}
            {registerCredentials.error === "inputError" ? 
                <div className="RegistrationTab__Errors">
                    <p>Please address the following errors:</p>
                    {Object.values(registerCredentials.error_input).map((item:string) => (
                        <p>{item}</p>
                    ))}
                </div>
            :
                registerCredentials.error !== "" ?
                        <p style={{color: "red"}}>{registerCredentials.error}</p> :
                            <></>
            }

            {/* Form */}
            <div className="RegistrationTab__Inputs">
                <TextField 
                    variant="outlined"
                    label="Email / Username"
                    onChange = {(e) => setRegisterCredentials({
                        ...registerCredentials,
                        username: e.target.value
                    })}
                    error={registerCredentials.error_input.username !== ""}
                />
                <TextField 
                    variant="outlined"
                    label="Password"
                    onChange = {(e) => setRegisterCredentials({
                        ...registerCredentials,
                        password: e.target.value
                    })}
                    error={registerCredentials.error_input.password !== ""}
                />
                <TextField 
                    variant="outlined"
                    label="Confirm Password"
                    onChange = {(e) => setRegisterCredentials({
                        ...registerCredentials,
                        password_repeat: e.target.value
                    })}
                    error={registerCredentials.error_input.password_repeat !== ""}
                />
                <TextField 
                    variant="outlined"
                    label="Name"
                    onChange = {(e) => setRegisterCredentials({
                        ...registerCredentials,
                        shortname: e.target.value
                    })}
                    error={registerCredentials.error_input.shortname !== ""}
                />
                <Button 
                    size="small" 
                    shape="round"
                    onClick={() => register()}
                >
                    Register
                </Button>
            </div>
        </div>
    )
}