/* eslint-disable react-hooks/exhaustive-deps */

import "../TableComponent/TableComponent.css"
import { useEffect, useState } from "react"
import { customStyle_empty_all, customSort } from "../../Constants/functions"
import { 
    DownOutlined, 
    UpOutlined ,
    Dropdown,
    MenuDropdown,
    RefreshIcon,
    LeftOutlined,
    RightOutlined,
    Table,
    Loader
} from "../../Constants/libraries";

type Props = {
    data:any[],
    triggerRetryRow?:any,
    loaderRetry:boolean
}

export const TableComponent: React.FC<Props> = (props) => {
    const [sortColumn, setSortColumn] = useState<{column:string, direction:boolean}>({column:"source", direction:false})
    const [paginateData, setPaginateData] = useState<{resultsCount:number, groupCount:number, page:number}>({resultsCount:0, groupCount:0, page:0})
    const [results, setResults] = useState<any[]>([])

    useEffect(() => {
        setResults([props.data])
        paginate()
    }, [props.data])

    const paginate = () => {
        const all = props.data.length
        const groups = Math.floor(props.data.length / 10)
        setPaginateData({
            ...paginateData,
            resultsCount: all,
            groupCount: groups
        })
    }

    const spliceResults = (value?:number) => {
        let results_paginated:any[] = []
        if (value) {
            let groups:number = Math.floor(props.data.length / value), remaining:number = props.data.length % value
            for (let i = 1, j = 0; i < groups + 1; i++) {
                results_paginated.push(props.data.slice(j, value + j))
                j += value
            }
            results_paginated.push(props.data.slice(remaining * -1))
        }
        else results_paginated.push(props.data)
        setResults(results_paginated)
    }

    const dropdownComponent = () => {
        let temp = []
        temp.push(
            <MenuDropdown.Item>
                <p onClick={() => spliceResults()}>All ({paginateData.resultsCount}) Results</p>
            </MenuDropdown.Item>              
        )
        for (let i = 1; i < paginateData.groupCount + 1; i++) {
            temp.push(
                <MenuDropdown.Item>
                    <p onClick={() => spliceResults(i * 10)}>{i * 10} Results</p>
                </MenuDropdown.Item>
            )
        }      
        return (
            <MenuDropdown>
                {temp}
            </MenuDropdown>
        )
    }

    const tableColumns = [

        // //Index
        // {
        //     title: ' ',
        //     dataIndex: 'key',
        //     key: 'key',
        //     width: '4%',
        //     render: (value: any) => (
        //         <>
        //             <p>{value}</p>
        //         </>
        //     ),
        // },

        //Source / Website
        {
            title: 
                <div className="TableComponent__displayFlex" style={{justifyContent: "center"}}>
                    <p style={{marginBottom: "none"}}>Source</p>
                    { sortColumn.column === "source" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#00FF00"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,
            dataIndex: 'source',
            key: 'source',
            width: '12%',
            onHeaderCell: (record:any) => ({
                onClick: () => {
                    setSortColumn({
                        ...sortColumn, 
                        column: record.key,
                        direction: !sortColumn.direction
                    })
                    let data_paginated:any[] = [...results[paginateData.page]], data_full:any[] = [...results]
                    customSort(data_paginated, record.key, sortColumn.direction)
                    data_full.splice(paginateData.page, 1, data_paginated)
                    setResults(data_full)
                }
            }),
            render: (value: any, index:any) => (
                <>
                    {
                        (index.partNumber === "Networkidle" || index.partNumber === "LoopTimeout" || index.partNumber === "CaptchaError") ? 
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                <a href={index.site} target="_blank" rel="noreferrer" style={{textAlign: "center", fontWeight: 400}}>{value}</a>
                                {props.triggerRetryRow &&
                                    <Loader
                                        type="Grid"
                                        color="#508FCD"
                                        height={50}
                                        width={50}
                                        visible={props.loaderRetry}
                                    />
                                }
                                    <RefreshIcon 
                                        onClick={() => {
                                            props.triggerRetryRow(index)
                                            // setLoaderRetry(false)
                                        }} 
                                        className="refresh"
                                    />
                                    <p>Retry</p>
                            </div>
                        :
                            <a href={index.site} target="_blank" rel="noreferrer" style={{textAlign: "center", fontWeight: 400}}>{value}</a>
                    }
                </>
            ),
        },

        //Part number
        {
            title: 
                <div className="TableComponent__displayFlex">
                    <p style={{marginBottom: "none"}}>Part Number</p>
                    { sortColumn.column === "partNumber" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#23B22C"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,                                                                                                                                                                                      
            dataIndex: 'partNumber',
            key: 'partNumber',
            width: '12%',
            onHeaderCell: (record:any) => ({
                onClick: () => {
                    setSortColumn({
                        ...sortColumn, 
                        column: record.key,
                        direction: !sortColumn.direction
                    })     
                    let data_paginated:any[] = [...results[paginateData.page]], data_full:any[] = [...results], data_error:any[] = [], data_filtered:any[] = []
                    data_paginated.forEach((item:any) => item.error ? data_error.push(item) : data_filtered.push(item))
                    customSort(data_filtered, record.key, sortColumn.direction)
                    data_full.splice(paginateData.page, 1, [...data_filtered, ...data_error])
                    setResults(data_full)
                }
            }),
            render: (value: any) => (
                <>
                    <p style={(value === "None" || value === "Networkidle" || value === "LoopTimeout") ? {fontWeight: 300} : {fontWeight: 400}}>
                        {value === "None" ? "No Results Found" : 
                            value === "Networkidle" ? "Network timeout. Please try again later" : 
                                value === "LoopTimeout" ? "Network timeout. Try again" : 
                                    value}
                    </p>
                </>
            ),
        },

        //Description
        {
            title: 
                <div className="TableComponent__displayFlex">
                    <p style={{marginBottom: "none"}}>Description</p>
                    { sortColumn.column === "description" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#23B22C"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,
            dataIndex: 'description',
            key: 'description',
            width: '14%',
            onHeaderCell: (record:any) => ({
                onClick: () => {
                    setSortColumn({
                        ...sortColumn, 
                        column: record.key,
                        direction: !sortColumn.direction
                    })
                    let data_paginated:any[] = [...results[paginateData.page]], data_full:any[] = [...results], data_error:any[] = [], data_filtered:any[] = []
                    data_paginated.forEach((item:any) => item.error ? data_error.push(item) : data_filtered.push(item))
                    customSort(data_filtered, record.key, sortColumn.direction)
                    data_full.splice(paginateData.page, 1, [...data_filtered, ...data_error])
                    setResults(data_full)
                }
            }),
        },

        //Manufacturer
        {
            title: 
                <div className="TableComponent__displayFlex">
                    <p style={{marginBottom: "none"}}>Manufacturer</p>
                    { sortColumn.column === "manufacturer" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#23B22C"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            width: '10%',
            onHeaderCell: (record:any) => ({
                onClick: () => {
                    setSortColumn({
                        ...sortColumn, 
                        column: record.key,
                        direction: !sortColumn.direction
                    })                  
                    let data_paginated:any[] = [...results[paginateData.page]],  data_full:any[] = [...results], data_empty:any[] = [], data_filtered:any[] = []
                    data_paginated.forEach((item:any) => (!item.manufacturer || item.manufacturer === "Not Specified") ? data_empty.push(item) : data_filtered.push(item))
                    customSort(data_empty, record.key, true)
                    customSort(data_filtered, record.key, sortColumn.direction)
                    data_full.splice(paginateData.page, 1, [...data_filtered, ...data_empty])
                    setResults(data_full)
                }
            }),
            render: (value: any) => <p style={customStyle_empty_all(value)}>{value}</p>,
        },

        //Alternate parts
        {
            title: 
                <div className="TableComponent__displayFlex">
                    <p style={{marginBottom: "none"}}>Alternate Parts</p>
                    { sortColumn.column === "alt" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#23B22C"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,
            dataIndex: 'alt',
            key: 'alt',
            width: '10%',
            onHeaderCell: (record:any) => ({
                onClick: () => {
                    setSortColumn({
                        ...sortColumn, 
                        column: record.key,
                        direction: !sortColumn.direction
                    })       
                    let data_paginated:any[] = [...results[paginateData.page]],  data_full:any[] = [...results], data_empty:any[] = [], data_filtered:any[] = []
                    data_paginated.forEach((item:any) => (!item.alt || item.alt === "None") ? data_empty.push(item) : data_filtered.push(item))
                    customSort(data_empty, record.key, true)
                    customSort(data_filtered, record.key, sortColumn.direction)
                    data_full.splice(paginateData.page, 1, [...data_filtered, ...data_empty])
                    setResults(data_full)   
                }
            }),
            render: (value: any) => <p style={customStyle_empty_all(value)}>{value}</p>,
        },

        //Supplier
        {
            title: 
                <div className="TableComponent__displayFlex">
                    <p style={{marginBottom: "none"}}>Supplier</p>
                    { sortColumn.column === "supplier" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#23B22C"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,
            dataIndex: 'supplier',  
            key: 'supplier',
            width: '10%',
            onHeaderCell: (record:any) => ({
                onClick: () => {
                    setSortColumn({
                        ...sortColumn, 
                        column: record.key,
                        direction: !sortColumn.direction
                    })  
                    let data_paginated:any[] = [...results[paginateData.page]],  data_full:any[] = [...results], data_empty:any[] = [], data_filtered:any[] = []
                    data_paginated.forEach((item:any) => (!item.supplier || item.supplier === "Not Specified") ? data_empty.push(item) : data_filtered.push(item))
                    customSort(data_empty, record.key, true)
                    customSort(data_filtered, record.key, sortColumn.direction)
                    data_full.splice(paginateData.page, 1, [...data_filtered, ...data_empty])
                    setResults(data_full)   
                }
            }),
            render: (value: any) => <p style={customStyle_empty_all(value)}>{value}</p>,
        },

        //Warehouse
        {
            title: 
                <div className="TableComponent__displayFlex">
                    <p style={{marginBottom: "none"}}>Warehouse</p>
                    { sortColumn.column === "warehouse" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#23B22C"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,
            dataIndex: 'warehouse',
            key: 'warehouse',
            width: "9%",
            onHeaderCell: (record:any) => ({
                onClick: () => {
                    setSortColumn({
                        ...sortColumn, 
                        column: record.key,
                        direction: !sortColumn.direction
                    })                    
                    let data_paginated:any[] = [...results[paginateData.page]],  data_full:any[] = [...results], data_empty:any[] = [], data_filtered:any[] = []
                    data_paginated.forEach((item:any) => (!item.warehouse || item.warehouse === "Not Specified") ? data_empty.push(item) : data_filtered.push(item))
                    customSort(data_empty, record.key, true)
                    customSort(data_filtered, record.key, sortColumn.direction)
                    data_full.splice(paginateData.page, 1, [...data_filtered, ...data_empty])
                    setResults(data_full)   
                }
            }),
            render: (value: any) => <p style={customStyle_empty_all(value)}>{value}</p>,
        },

        //Stock
        {
            title: 
                <div className="TableComponent__displayFlex" style={{justifyContent: "flex-end"}}>
                    <p style={{marginBottom: "none"}}>Stock</p>
                    { sortColumn.column === "stock" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#23B22C"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,
            dataIndex: 'stock',
            key: 'stock',
            width: "9%",
            onHeaderCell: (record:any) => 
            (
                {
                    onClick: () => {
                        setSortColumn({
                            ...sortColumn, 
                            column: record.key,
                            direction: !sortColumn.direction
                        })          
                        let data_paginated:any[] = [...results[paginateData.page]],  data_full:any[] = [...results], data_empty:any[] = [], data_filtered:any[] = []
                        data_paginated.forEach((item:any) => (isNaN(item.stock) || !item.stock) ? data_empty.push(item) : data_filtered.push(item))
                        customSort(data_empty, record.key, true)
                        customSort(data_filtered, record.key, sortColumn.direction, true)
                        data_full.splice(paginateData.page, 1, [...data_filtered, ...data_empty])
                        setResults(data_full)   
                    }
                }
            ),
            render: (value: any) => <p style={customStyle_empty_all(value)}>{value}</p>,
        },

        //Price
        {
            title: 
                <div className="TableComponent__displayFlex" style={{justifyContent: "center"}}>
                    <p style={{marginBottom: "none"}}>Unit Price ($)</p>
                    { sortColumn.column === "price" ? 
                        (sortColumn.direction ? <UpOutlined style={{color: "#23B22C"}}/> : <DownOutlined style={{color: "#FF0000"}}/>)
                    :
                        <></>
                    }
                </div>,
            dataIndex: 'price',
            key: 'price',
            width: '10%',
            onHeaderCell: (record:any) => ({
                onClick: () => {
                    setSortColumn({
                        ...sortColumn, 
                        column: record.key,
                        direction: !sortColumn.direction
                    })            
                    let data_paginated:any[] = [...results[paginateData.page]],  data_full:any[] = [...results], data_empty:any[] = [], data_filtered:any[] = []
                    data_paginated.map((item:any) => item.price_sort = item.price.replace(/[^\d.-]/g, ''))
                    data_paginated.forEach((item:any) => !item.price_sort ? data_empty.push(item) : data_filtered.push(item))
                    customSort(data_empty, record.key, true)
                    customSort(data_filtered, "price_sort", sortColumn.direction, true)
                    data_full.splice(paginateData.page, 1, [...data_filtered, ...data_empty])
                    setResults(data_full)  
                }
            }),
            render: (value: any) => <p style={customStyle_empty_all(value)}>{value}</p>,
        },
    ]

    return (
        results.length ? 
            <div className="TableComponent">
                <>
                    <p style={{textAlign: "left"}}>{props.data.length} Results Found</p>
                    <Dropdown overlay={dropdownComponent()}>
                        <div className="TableComponent__dropdown">
                            <p>Displaying All Results</p>
                            <DownOutlined />
                        </div>
                    </Dropdown>
                    <p style={{textAlign: "right", fontWeight: 400}}>Sorted by: {sortColumn.column.toUpperCase()}</p>
                    <Table 
                        emptyText="No Results Found"
                        columns={tableColumns} 
                        data={results[paginateData.page]} 
                    />
                    <div className="TableComponent__pagination">
                        <LeftOutlined 
                            onClick={() => {
                                paginateData.page !== 0 &&
                                    setPaginateData({
                                        ...paginateData,
                                        page: paginateData.page - 1
                                    })
                            }}
                        />
                        <RightOutlined
                            onClick={() => {
                                paginateData.page !== (results.length - 1) &&
                                    setPaginateData({
                                        ...paginateData,
                                        page: paginateData.page + 1
                                    })
                            }}
                        />
                        <p>Page {paginateData.page + 1} of {results.length}</p>
                    </div>
                </>
            </div>
        :
            <></>
    )
}