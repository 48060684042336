import "./Contact.css";
import { useState } from "react"
import {
    addMsia,
    addUsa,
    contactInfoMsia,
    contactInfoUS
} from "../../Constants/texts"
import {
    Helmet,
    LocationOnIconOutlined,
    Backdrop,
    Loader,
    Snackbar,
    TextField,
    Chip,
    Button,
    useMediaQuery,
} from "../../Constants/libraries"
import { emailBody } from "../../Constants/types";

export const Contact = () => {

    const phone = useMediaQuery({ query: "(max-width: 400px)"})
    const [email, setEmail] = useState<emailBody>({ name: "", contact:"", message: "" })
    const [loader, setLoader] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<boolean>(false)

    async function send() {
        try {
            setLoader(true)
            var finalString = {
                name: email.name,
                contact: email.contact,
                message: email.message
            }
            await fetch("https://aerotrendsaviationbackend.com/message", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: 'post',
                body: JSON.stringify(finalString),
            }).then(() => {
                setLoader(false)
                setSnackbar(true)
                setTimeout(() => {
                    setSnackbar(false)
                }, 3000)
            })
        }
        catch (e) {
            console.log("Email Error", e)
            setLoader(false)
        }
    }

    return (
        <div>
            <Helmet>
                <title>Contact | Aerotrends Aviation</title>
            </Helmet>

            <div id="top" className="Contact">

                {/* Indications */}
                <Backdrop className="Contact__Backdrop" open={loader} transitionDuration={1000}>
                    <div className="Contact__Loader">
                        <Loader
                            type="Puff"
                            color="#508FCD"
                            height={100}
                            width={100}
                        />
                        <p>Loading</p>
                    </div>
                </Backdrop>
                <Snackbar className="snackbar" message="Message Sent!" open={snackbar} onClose={() => setSnackbar(false)}/>

                <p className="title">CONTACT US ANYTIME, YOUR TIME!</p>
                <div>
                    {/* Offices */}
                    <div className="Contact__first-section">
                        <div className="Contact__info">
                            <p>Aerotrends Aviation (M) SDN BHD</p>
                            <a href="https://maps.google.com/?q=5 Jalan Kartunis U1/47, Glenmarie Temasya 40150 Shah Alam, Selangor, Malaysia">
                                <div className="address">
                                    <span className="icon"><LocationOnIconOutlined fontSize="small"/></span>
                                    <span className="override">{addMsia}</span>
                                </div>
                            </a>
                            {contactInfoMsia.map(itemM => (
                                <div>
                                    <Chip
                                        variant="outlined"
                                        className="override"
                                        icon={<itemM.logo fontSize="small"/>}
                                        label={
                                            <a className="override" href={itemM.href}>{itemM.text}</a>
                                        }
                                        style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="Contact__info">
                            <p>Aerotrends Aviation LLC</p>
                            <a href="https://maps.google.com/?q=1200 Texan Trail, 76051 Grapevine, TX, USA">
                                <div className="address">
                                    <span className="icon"><LocationOnIconOutlined fontSize="small"/></span>
                                    <span className="override">{addUsa}</span>
                                </div>
                            </a>
                            {contactInfoUS.map(item => (
                                <div>
                                    <Chip
                                        variant="outlined"
                                        icon={<item.logo fontSize="small"/>}
                                        label={
                                            <a className="override" href={item.href}>{item.text}</a>
                                        }
                                        style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Email */}
                    <div className="Contact__second-section">
                        <TextField 
                            style={{width: "auto"}}
                            variant="outlined"
                            label="Name"
                            onChange = {event => setEmail({...email, name: event.target.value})}
                        />
                        <TextField 
                            style={{width: "auto"}}
                            variant="outlined"
                            label="Contact Info"
                            placeholder="Phone, Email, Fax"
                            margin="normal"
                            onChange = {event => setEmail({...email, contact: event.target.value})}
                        />
                        <TextField 
                            label="Message"
                            fullWidth
                            multiline
                            variant="outlined"
                            margin="normal"
                            rows = {10}
                            onChange = {event => setEmail({...email, message: event.target.value})}
                        />
                        <Button 
                            size="large" 
                            shape="round"
                            onClick = {() => send()}
                        >
                            SEND
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
