import { PrivacyPolicy } from "../../Components/PrivacyPolicy/privacy-policy"
import { Navbar } from "../../Components/Navbar/Navbar"
import { Footer } from "../../Components/Footer/Footer"

type Props = {
    className?: string;
}

export const privacyPolicy: React.FC<Props> = (props) => {
    return (
        <>
            <Navbar contrast/>
            <PrivacyPolicy/>
            <Footer/>
        </>
    )
}