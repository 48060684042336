/* eslint-disable @typescript-eslint/no-unused-vars, jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */

import "./LoginTab.css"
import { useContext, useEffect, useState } from "react"
import { 
    Button,
    TextField,
    Loader,
    Backdrop,
    Cookies,
    Alert,
    Modal,
    Snackbar,
    Redirect,
    InputAdornment,
    IconButton,
    VisibilityOffIcon,
} from "../../Constants/libraries"
import { LoginForm } from "../../Constants/types"
import { Context_Username } from "../../Constants/contexts"
import { validateSession } from "../../Constants/functions"

export const LoginTab = () => {

    //Login
    const [credentials, setCredentials] = useState<LoginForm>({
        username: "",
        password: "", 
        verification: "",
        verification_code: true,
        error: ""
    })
    const [username, setUsername] = useContext(Context_Username)
    const [redirect, setRedirect] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    //Indicators
    const [initiateLogin, setInitiateLogin] = useState<boolean>(false)
    const [loginModal, setLoginModal] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<boolean>(false)
    const [snackbarEmail, setSnackbarEmail] = useState<boolean>(false)

    useEffect(() => {
        const authenticate = async () => {
            const session = await validateSession()
            if (session) {
                setUsername(session) 
                setRedirect(true)
            }
            else {
                setUsername("")
                setRedirect(false)
            }
        }
        authenticate()
    }, [])

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    //Runs when user clicks login
    const validateLogin = async () => {
        setInitiateLogin(true)
        const login = {
            username: credentials.username,
            password: credentials.password,
            verification: credentials.verification
        }

        try {
            //Check if password matches backend password
            const valid_login = await fetch("https://aerotrendsaviationbackend.com/login/validate_login", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                method: 'post',
                body: JSON.stringify(login)
            }).then(data => data.json())

            //Error handling
            if (valid_login[0] === "InvalidUsername")
                setCredentials({
                    ...credentials,
                    error: "Username does not exist."
                })
            else if (valid_login[0] === "NotVerified") {
                setCredentials({
                    ...credentials,
                    error: "You have not verified your account."
                })
                setLoginModal(true)
            }
            else if (valid_login[0] === "Networkidle")
                setCredentials({
                    ...credentials,
                    error: "An unexpected error occured. Please try again later."
                }) 
            else if (valid_login[0] === "InvalidLogin")
                setCredentials({
                    ...credentials,
                    error: "Username or password is incorrect."
                }) 
            else {
                setCredentials({
                    ...credentials,
                    error: ""
                })
                setUsername(valid_login[0])
                postSession()    
            }

            setInitiateLogin(false)
        }
        catch(e) {
            console.log("Validating Login Error", e)
            setCredentials({
                ...credentials,
                error: "An unexpected error occured. Please try again."
            })
            setInitiateLogin(false)
        }
    }

    //Save cookies
    const postSession = async () => {
        const session = { username: credentials.username }   

        try {
            //Get new token
            const post_session = await fetch("https://aerotrendsaviationbackend.com/login/post_session", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                method: 'post',
                body: JSON.stringify(session)
            }).then(data => data.json())

            //Store cookies
            if (post_session[0] !== "InvalidSession" || post_session[0] !== "Networkidle") {
                const cookies = new Cookies()
                cookies.set("session_username", credentials.username, { 
                    path: '/', 
                    expires: new Date(Date.now() + (2 * 7200000))
                })
                cookies.set("session_token", post_session[0].token, { 
                    path: '/', 
                    expires: new Date(Date.now() + (2 * 7200000))
                })
                cookies.set("session_shortname", post_session[0].shortname, { 
                    path: '/', 
                    expires: new Date(Date.now() + (2 * 7200000))
                })
            }
        }
        catch(e) {
            console.log("Post Session Error", e)
        }
        
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    //Runs when user clicks resend code
    const resendVerificationCode = async () => {
        const username = {
            username: credentials.username
        }

        try {
            const resend_uf = await fetch("https://aerotrendsaviationbackend.com/login/resend_verification", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                method: 'post',
                body: JSON.stringify(username)
            })
            const resend = await resend_uf.json()

            if (resend[0] === "VerificationEmailSent") {
                setSnackbarEmail(true)
                setTimeout(() => {
                    setSnackbarEmail(false)
                }, 3000)
            }
        }
        catch(e) {
            console.log("Resend Verification Code Error", e)
            setLoginModal(false)
            setCredentials({
                ...credentials,
                error: "An unexpecter error occured. Please try again later."
            })
        }
    }

    //Runs when user clicks ok in verification modal
    const verifyVerificationCode = async () => {
        const verify = {
            username: credentials.username,
            verification: credentials.verification,
        }

        try {
            setInitiateLogin(true)
            const verification_uf = await fetch("https://aerotrendsaviationbackend.com/login/verify_user", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                method: 'post',
                body: JSON.stringify(verify)
            })
            const verification = await verification_uf.json()

            if (verification[0] === "InvalidVerificationCode") {
                setCredentials({
                    ...credentials,
                    verification_code: false
                })
            }
            else if (verification[0] === "ValidVerification") {
                setCredentials({
                    ...credentials,
                    verification_code: true
                })
                setSnackbar(true)
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            }
            else {
                setLoginModal(false)
                setCredentials({
                    ...credentials,
                    error: "An unexpecter error occured. Please try again later."
                })
            }
            setInitiateLogin(false)
        }    
        catch(e) {
            console.log("Verify Verification Code Error", e)
            setLoginModal(false)
            setCredentials({
                ...credentials,
                error: "An unexpecter error occured. Please try again later."
            })
            setInitiateLogin(false)
        }
    }

    return (
        <div className="LoginTab">
            {redirect ? <Redirect to="/dashboard"/> : <></>}

            {/* Loader */}
            <Backdrop className="LoginTab__Backdrop" open={initiateLogin} transitionDuration={1000}>
                <div className="LoginTab__Loader">
                    <Loader
                        type="Puff"
                        color="#508FCD"
                        height={100}
                        width={100}
                    />
                    <p>Logging In</p>
                </div>
            </Backdrop>

            {/* Popup */}
            <Snackbar open={snackbar} autoHideDuration={3000} onClose={() => setSnackbar(false)}>
                <Alert onClose={() => setSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Verification successful. You will be redirected to log in again.
                </Alert>
            </Snackbar>
            <Snackbar open={snackbarEmail} autoHideDuration={3000} onClose={() => setSnackbarEmail(false)}>
                <Alert onClose={() => setSnackbarEmail(false)} severity="success" sx={{ width: '100%' }}>
                    Verification email resent.
                </Alert>
            </Snackbar>

            {/* Modal */}
            <Modal
                title=""
                centered
                visible={loginModal}
                onCancel={() => setLoginModal(false)}
                onOk={async () => await verifyVerificationCode()}
                closable
                width={1000}
            >
                <div className="LoginTab__Modal">
                    <p>Enter the verification code you received in your email:</p>
                    <TextField 
                        style={{width: "auto"}}
                        variant="filled"
                        label="Verification Code"
                        onChange = {(e) => setCredentials({
                            ...credentials,
                            verification: e.target.value
                        })}
                        error={!credentials.verification_code}
                        helperText={!credentials.verification_code ? "Incorrect Verification Code" : ""}
                    />
                    <a onClick={() => resendVerificationCode()}>Resend Verification Code</a> 
                </div>
            </Modal>

            {/* Title */}
            <div className="LoginTab__Title">
                <p>Employee log in</p>
                <p>Log in to view dashboard</p>
            </div>

            {/* Errors */}
            {credentials.error !== "" ? 
                <div className="LoginTab__Errors">
                    <p style={{color: "red"}}>{credentials.error}</p>
                </div>
            :
                <></>
            }

            {/* Inputs */}
            <div className="LoginTab__Inputs">
                <TextField 
                    style={{width: "auto"}}
                    variant="outlined"
                    label="Email / Username"
                    onChange = {(e) => setCredentials({
                        ...credentials,
                        username: e.target.value
                    })}
                />
                <TextField 
                    style={{width: "auto"}}
                    variant="outlined"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    onChange = {(e) => setCredentials({
                        ...credentials,
                        password: e.target.value
                    })}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <VisibilityOffIcon onClick={handleShowPassword} />
                            </IconButton>
                          </InputAdornment>
                        )
                    }}
                />
                <Button 
                    size="small" 
                    shape="round"
                    onClick={() => validateLogin()}
                >
                    Login
                </Button>
            </div>
        </div>
    )
}
