import "./Homepage.css"
import { useState } from "react"
import {
    Helmet,
    HashLink,
    Button,
    useMediaQuery,
} from "../../Constants/libraries"
import {
    vidUrl_webm, 
    vidError, 
    header_main, 
    header_second
} from "../../Constants/texts"

export const Homepage = () => {
    
    const tablet = useMediaQuery({ query: "(max-width: 770px)"})
    const url = tablet ? "https://aerotrendsaviation-videobucket.s3.amazonaws.com/homepage/plane-modified.mp4" 
                       : "https://aerotrendsaviation-videobucket.s3.amazonaws.com/homepage/plane-modified.mp4"
    const [isVideoLoaded, setIsVideoLoaded] = useState(false)
    const onLoadedData = () => {
        setIsVideoLoaded(true)
    }

    return (
        <div className="Homepage">
            <Helmet>
                <title>Aerotrends Aviation</title>
            </Helmet>

            {/* Loader */}
            {/* <Backdrop className="Homepage__Backdrop" open={isVideoLoaded} transitionDuration={1000}>
                <div className="Homepage__Loader">
                    <Loader
                        type="Puff"
                        color="#508FCD"
                        height={100}
                        width={100}
                    />
                    <p>Loading</p>
                </div>
            </Backdrop> */}

            {/* Video */}
            <video 
                loop 
                autoPlay 
                muted
                onLoadedData={onLoadedData}
                style={{ opacity: isVideoLoaded ? 1 : 0}}
            >
                <source src={url} type="video/mp4"/>
                <source src={vidUrl_webm} type="video/webm"/>
                {vidError}
            </video>

            {/* Content */}
            <div className="Homepage__content">
                <p>{header_main}</p>
                <div style={{ opacity: isVideoLoaded ? 1 : 0}}>
                    <p>{header_second}</p>
                    <div className="Homepage__buttons">
                        <Button size="large" shape="round"><HashLink to="/about#top">VIEW MORE</HashLink></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}