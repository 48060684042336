/* eslint-disable react-hooks/exhaustive-deps */

import "./Quote.css"
import { 
    useMediaQuery, 
    Tabs,
    Redirect,
} from "../../Constants/libraries"
import {
    Acinv, 
    All, 
    Aviall,
    Bd, 
    Dasi, 
    Proponent, 
    Satair, 
    Wencor
} from "../QuoteComponents/index"
import { useContext, useEffect, useState } from "react"
import { validateSession } from "../../Constants/functions"
import { Context_Username } from "../../Constants/contexts"

export const Quote = () => {

    const [redirect, setRedirect] = useState(false)
    const { TabPane } = Tabs
    const laptop = useMediaQuery({ query: "(max-width: 1200px)"})
    // eslint-disable-next-line -- what do I need this for?
    const [username, setUsername] = useContext(Context_Username)

    useEffect(() => {
        const authenticate = async () => {
            const session = await validateSession()
            if (session) {
                setUsername(session) 
                setRedirect(false)
            }
            else {
                setUsername("")
                setRedirect(true)
            }
        }
        authenticate()
    }, [])

    return (
        <div className="Quote">
            {redirect ? <Redirect to="/login"/> : <></>}
            <p className="Quote__title">Quotations</p>

            {/* Search All */}
            <All/>

            {/* Search Individual */}
            <div className="Quote__results">
                <p className="searchTitle">Search Individual</p>

                <Tabs defaultActiveKey="1" tabPosition={laptop ? "top" : "right"} style={{ height: "auto" }}>
                    <TabPane tab="ACINV" key="1">
                        <div>
                            <Acinv/>
                        </div>
                    </TabPane>
                    <TabPane tab="WENCOR" key="2">
                        <div>
                            <Wencor/>
                        </div>                       
                    </TabPane>
                    <TabPane tab="DASI" key="3">
                        <div>
                            <Dasi/>
                        </div>                       
                    </TabPane>
                    <TabPane tab="PROPONENT" key="4">
                        <div>
                            <Proponent/>
                        </div>                       
                    </TabPane>
                    <TabPane tab="BOEING DISTRIBUTION" key="5">
                        <div>
                            <Bd/>
                        </div>                       
                    </TabPane>
                    <TabPane tab="AVIALL" key="7">
                        <div>
                            <Aviall/>
                        </div>                       
                    </TabPane>
                    <TabPane tab="SATAIR" key="6">
                        <div>
                            <Satair/>
                        </div>                       
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}