import "../Quote/Quote.css"
import { useState } from "react"
import { TableComponent } from "../TableComponent/TableComponent"
import { formatAPIParams, urls } from "../../Constants/functions"
import { 
    Button,
    TextField,
    Loader,
} from "../../Constants/libraries"

export const All = () => {

    //Loaders
    const [loaderAll, setLoaderAll] = useState<boolean>(false)
    const [loaderRetry, setLoaderRetry] = useState<boolean>(false)

    //Query & Array
    const [query, setQuery] = useState("")
    const [quantity, setQuantity] = useState("")
    const [all, setAll] = useState<any[]>([])

    const getAll = async (query:string, quantity:string) => {  

        try {
            //Initialize
            setLoaderAll(true)
            let apiParams:any = formatAPIParams(query, quantity)
            let rowsToUpdate:{data:any[], index:number}[] = []
            const response_uf = await fetch(`https://aerotrendsaviationbackend.com/all/${apiParams[0]}/${apiParams[1]}`)
            let response = await response_uf.json()

            //Format response and return
            let results:any = response.flat()
            results.map((item:any, index:number) => {
                item = formatData(item)
                if (item.length) {
                    let temp = {
                        data: item,
                        index: index
                    }
                    rowsToUpdate.push({...temp})
                }
                return item
            })
            finalizeData(results, rowsToUpdate)
            setLoaderAll(false)
        }
    
        catch(e) {
            setAll(["Networkidle"])
            console.log("getAll - Error", e)
            setLoaderAll(false)
        }
    }

    const formatData = (item:any) => {
        let rowsToAdd:any[] = []

        //Format based on case
        item.partNumber = item.error ? item.error : item.partNumber
        switch (item.source) {
            case "ACINV":
                item.certNotes = item.certNotes.join(', ')
                break
            case "WENCOR":
                item.alt = item.alt.join(', ')
                break
            case "PROPONENT":
                if (item.stock.length > 1) {
                    item.stock.map((item_sub:any) => {
                        let temp:any = item
                        temp.stock = item_sub.warehouseQuantity
                        temp.warehouse = item_sub.warehouseName
                        rowsToAdd.push({...temp})
                        return item
                    })
                    item = rowsToAdd
                }
                else if (item.stock.length === 1) {
                    let temp = item.stock[0]
                    item.warehouse = temp.warehouseName
                    item.stock = temp.warehouseQuantity
                }
                else {
                    item.warehouse = ""
                    item.stock = ""
                }
                break
            case "BD":
                item.alt = item.alt.join(', ')
                break
        }
        return item
    }

    async function retryRow(row:any) {

        //Initialize
        setLoaderRetry(true)
        let apiParams:string = formatAPIParams(query, quantity).join('/')
        let rowsToUpdate:{data:any[], index:number}[] = []

        try {                    
            //Specific API call
            const url = urls.get(row.source)
            const response_uf = await fetch(`https://aerotrendsaviationbackend.com/${url}${apiParams}`)
            let response = await response_uf.json() 

            //Format data
            response.map((item:any, index:number) => {                                                            
                item.source = row.source
                item = formatData(item)
                if (item.length) {
                    let temp = {
                        data: item,
                        index: index
                    }
                    rowsToUpdate.push({...temp})
                }
                return item
            })
            
            //This rarely happens, so I will write here
            rowsToUpdate.forEach((item:any) => {
                let adjuster = 0
                response.splice(item.index + adjuster, item.data.length, ...item.data)
                adjuster += item.data.length - 1
            })

            //Merge it back with data
            let data:any[] = all
            data.splice(row.key - 1, 1, ...response)
            finalizeData(data)
            setLoaderAll(false)
            setLoaderRetry(false)
        }

        catch(e) {
            console.log("retryRow - Error", e)
            //fix
            setAll(["Networkidle"])
        }
    }

    const finalizeData = (data:any[], updateData?:{data:any[], index:number}[]) => {

        //Modify data if there are rows to add
        if (updateData) {
            let adjuster = 0
            updateData.forEach((item:any) => {
                data.splice(item.index + adjuster, 1, ...item.data)
                adjuster += item.data.length - 1
            })
        }

        //Add keys and update state
        data.map((item:any, index:number) => {
            item.key = index + 1
            return item
        })
        setAll([...data])
    }

    return (
        <>
            <div className="Quote__search">
                <TextField
                    style={{width: "auto"}}   
                    variant="outlined"
                    label="Part Number"
                    required
                    onChange = {event => {setQuery(event.target.value)}}
                />
                <TextField
                    style={{width: "200px"}}
                    variant="outlined"
                    label="Quantity"
                    margin="dense"
                    error={!/^[0-9]*$/.test(quantity)}
                    onChange = {event => {setQuantity(event.target.value)}}
                    helperText={!/^[0-9]*$/.test(quantity) ? "Not an Integer" : ""}
                />
                <Button 
                    size="small" 
                    shape="round"
                    disabled={loaderAll}
                    onClick={() => {getAll(query, quantity)}}
                >
                    ALL
                </Button>
                {all[0] === "Networkidle" ? <p>An Error Occured</p> : 
                    !all.length ? <></> : 
                        <TableComponent data={all} triggerRetryRow={retryRow} loaderRetry={loaderRetry}/>
                }
                <Loader
                    type="Grid"
                    color="#508FCD"
                    height={50}
                    width={50}
                    visible={loaderAll}
                />
            </div>
            <hr className="mainHR"/>
        </>
    )
}