/* eslint-disable react-hooks/exhaustive-deps */

import "./Dashboard.css"
import { Card, Meta, Redirect } from "../../Constants/libraries"
import { useContext, useEffect, useState } from "react"
import { Context_Username } from "../../Constants/contexts"
import { validateSession } from "../../Constants/functions"

export const Dashboard = () => {

    //States
    const [redirect, setRedirect] = useState<string>()
    const [username, setUsername] = useContext(Context_Username)

    useEffect(() => {
        const authenticate = async () => {
            const session = await validateSession()
            if (session) {
                setUsername(session) 
                setRedirect("")
            }
            else {
                setUsername("")
                setRedirect("login")
            }
        }
        authenticate()
    }, [])

    return (
        <div className="Dashboard">

            {/* Titles */}
            <div className="Dashboard__Intro">
                <p>Dashboard</p>
                <p>Welcome, {username}</p>
            </div>

            {/* Cards */}
            <div className="Dashboard__Cards">
                <Card
                    onClick={() => setRedirect("quote")}
                    className="Dashboards__Cards__Card"
                    hoverable
                    style={{ width: 400 }}
                    cover={<img alt="Quotations" src="https://aerotrendsaviation-videobucket.s3.amazonaws.com/dashboard/thumbnail_quotation.jpg"/>}
                >
                    <Meta title="Quotations" description="Search for parts across a few sources" className="Dashboard__Cards__Meta"/>
                </Card>  

                 <Card
                    onClick={() => setRedirect("parser")}
                    className="Dashboards__Cards__Card"
                    hoverable
                    style={{ width: 400 }}
                    cover={<img alt="example" src="https://aerotrendsaviation-videobucket.s3.amazonaws.com/dashboard/thumbnail_parser.jpg"/>}
                >
                    <Meta title="ILS Parser" description="Parse ILS batch file (Excel)" className="Dashboard__Cards__Meta"/>
                </Card>   
            </div>

            {/* Redirect */}
            {redirect ? <Redirect to={`/${redirect}`}/> : <></>}
        </div>
    )
}