import { Navbar } from "../../Components/Navbar/Navbar"
import { Footer } from "../../Components/Footer/Footer"
import { Dashboard } from "../../Components/Dashboard/Dashboard"

export const dashboard = () => {

    return (
        <>
            <Navbar contrast/>
            <Dashboard/>
            <Footer/>
        </> 
    )
}