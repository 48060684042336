import "../Quote/Quote.css"
import { useState } from "react"
import { QuoteInfoDASI } from "../../Constants/types"
import { customStyle_empty, formatAPIParams } from "../../Constants/functions"
import { 
    AttachMoney, 
    GridOnIcon, 
    AddAlarmIcon,
    AccessTimeIcon,
    Button,
    TextField,
    Chip,
    Loader,
    useMediaQuery,
} from "../../Constants/libraries"

export const Dasi = () => {
    const phone = useMediaQuery({ query: "(max-width: 400px)"})
    const [loaderDASI, setLoaderDASI] = useState<boolean>(false)
    const [dasi, setDasi] = useState<QuoteInfoDASI[] | []>([])
    const [query, setQuery] = useState<string>("") 

    async function getDasi(query:string) {

        try {
            //Initialize API
            setLoaderDASI(true)
            let apiParams = formatAPIParams(query)
            const responseFirst = await fetch(`https://aerotrendsaviationbackend.com/dasi/${apiParams[0]}/`)
            let response = await responseFirst.json()
            setDasi(response)
        }
        catch(e) {
            console.log("Dasi Parsing Error", e)
            setDasi([])
        }
        setLoaderDASI(false)
    }

    return (
        <>
            <div className="Quote__results__container">
                <div className="Quote__results__container__header">

                    {/* Input */}
                    {dasi.length ? <p className="bold">{query}</p> : <></>}
                    <div>
                        <TextField
                            style={{width: "auto"}}   
                            variant="outlined"
                            label="Part Number"
                            required
                            onChange = {event => {setQuery(event.target.value)}}
                        />
                        <Button 
                            disabled={loaderDASI}
                            size="small" 
                            shape="round"
                            onClick={() => {getDasi(query)}}
                        >
                            DASI
                        </Button>
                        <Loader
                            type="Grid"
                            color="#508FCD"
                            height={50}
                            width={50}
                            visible={loaderDASI}
                        />
                    </div>
                </div>

                {/* Results */}
                <div>
                    {dasi.map((item:any) => !dasi[0].error ? (
                        <div>
                            <p style={{fontWeight: "bold"}}>{item.partNumber}</p>
                            <p>{item.description}</p>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-price"
                                    icon={<AttachMoney fontSize="small"/>}
                                    label={`Unit Price: ${item.price}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-stock"
                                    icon={<GridOnIcon fontSize="small"/>}
                                    label={`Quantity: ${item.stock}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                                <Chip
                                    variant="outlined"
                                    className="chip-stock"
                                    icon={<AccessTimeIcon fontSize="small"/>}
                                    label={`Lead Time: ${item.leadTime}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-condition"
                                    icon={<AddAlarmIcon fontSize="small"/>}
                                    label={`Condition: ${item.condition}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <p style={customStyle_empty(item.supplier)}><span>Supplier: </span>{item.supplier}</p>
                            <p style={customStyle_empty(item.warehouse)}><span>Warehouse: </span>{item.warehouse}</p>
                            <p style={customStyle_empty(item.manufacturer)}><span>Manufacturer: </span>{item.manufacturer}</p>
                            <p style={customStyle_empty(item.alt)}><span>Alternate: </span>{item.alt}</p>
                            <hr/>
                        </div>
                    )
                        : 
                    (
                        <>
                            {dasi[0].error === "None" && <p>No Results Found</p>}
                            {dasi[0].error === "Networkidle" && <p>Network Timeout. Please Try Again</p>}
                            {dasi[0].error === "CaptchaError" && <p>Captcha Incorrect. Please Try Again</p>}  
                        </> 
                    ))}
                </div>
            </div>
            <hr className="mainHR"/>
        </>
    )
}