import { Contact } from "../../Components/Contact/Contact"
import { Navbar } from "../../Components/Navbar/Navbar"
import { Footer } from "../../Components/Footer/Footer"

type Props = {
    className?: string;
}

export const contact: React.FC<Props> = (props) => {
    return (
        <>
            <Navbar contrast/>
            <Contact/>
            <Footer/>
        </>
    )
}