import "./privacy-policy.css"
import {
    Helmet,
    HashLink
} from "../../Constants/libraries"
import {
    privacyPolicyHeader,
    informationCollection,
    logData,
    cookies,
    serviceProviders,
    security,
    otherSitesLinks,
    changesPP
} from "../../Constants/texts"

export const PrivacyPolicy = () => {
    
    return (
        <div id="top" className="Privacy">
            <Helmet>
                <title>Privacy Policy | Aerotrends Aviation</title>
            </Helmet>

            <p className="header">Privacy Policy</p>
            {privacyPolicyHeader.map((item) => (
                <p>{item}</p>
            ))}
            <hr/>

            <p className="header">Information Collection and Use</p>
            <p>{informationCollection}</p>
            <hr/>

            <p className="header">Log Data</p>
            <p>{logData}</p>
            <hr/>

            <p className="header">Cookies</p>
            {cookies.map((item) => (
                <p>{item}</p>
            ))}
            <hr/>

            <p className="header">Service Providers</p>
            <div className="italic">
                {serviceProviders.map((item) => (
                    <p>{item}</p>
                ))}
            </div>
            <hr/>

            <p className="header">Security</p>
            <p>{security}</p>
            <hr/>

            <p className="header">Links to Other Sites</p>
            <p>{otherSitesLinks}</p>
            <hr/>

            <p className="header">Changes to Current Privacy Policy</p>
            <p>{changesPP}</p>
            <hr/>
            
            <p className="contact">If you have any questions or suggestions about our Privacy Policy, do not hesitate to <span><HashLink to="/contact#top" className="contactLink">contact us</HashLink></span>.</p>
            <p className="contact">Website By <span><a href="https://www.pphilip.com" className="contactLink">Paul</a></span></p>
        </div>
    )
}