import "./quote.css";
import { Quote } from "../../Components/Quote/Quote";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Footer } from "../../Components/Footer/Footer";

type Props = {
    className?: string;
}

export const quote: React.FC<Props> = (props) => {
    return (
        <>
            <Navbar contrast/>
            <Quote/>
            <Footer/>
        </>
    )
}