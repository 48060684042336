/* eslint-disable @typescript-eslint/no-unused-vars, jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */

import "./Login.css"
import { useState } from "react"
import { 
    Loader,
    Backdrop,
    Tabs,
} from "../../Constants/libraries"
import { LoginTab } from "../LoginTab/LoginTab"
import { RegistrationTab } from "../RegistrationTab/RegistrationTab"

export const Login = () => {

    //Loader
    const [initiateSession, setInitiateSession] = useState<boolean>(false)

    //Run on first page render
    const { TabPane } = Tabs

    return (
        <div className="Login">

            {/* Indications */}
            <Backdrop className="Login__Backdrop" open={initiateSession} transitionDuration={1000}>
                <div className="Login__Loader">
                    <Loader
                        type="Puff"
                        color="#508FCD"
                        height={100}
                        width={100}
                    />
                    <p>Loading Previous Session</p>
                </div>
            </Backdrop>

            {/* Signup / Signin */}
            <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: "auto" }}>
                <TabPane tab="Login" key="1">
                    <LoginTab/>
                </TabPane>
                <TabPane tab="Register" key="2">
                    <RegistrationTab/>
                </TabPane>
            </Tabs>
        </div>
    )
}