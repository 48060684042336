import "../Quote/Quote.css"
import { useState } from "react"
import { QuoteInfoCustom } from "../../Constants/types"
import { formatAPIParams, customStyle_empty } from "../../Constants/functions"
import { 
    AttachMoney, 
    GridOnIcon, 
    Button,
    TextField,
    Chip,
    Loader,
    useMediaQuery,
} from "../../Constants/libraries"

export const Wencor = () => {
    const phone = useMediaQuery({ query: "(max-width: 400px)"})
    const [loaderWENCOR, setLoaderWENCOR] = useState<boolean>(false)
    const [wencor, setWencor] = useState<QuoteInfoCustom[] | []>([])
    const [query, setQuery] = useState<string>("") 

    async function getWencor(query:string) {

        try {
             //Initialize API
            setLoaderWENCOR(true)
            let apiParams = formatAPIParams(query)
            const responseFirst = await fetch(`https://aerotrendsaviationbackend.com/wencor/${apiParams[0]}/`)
            let response = await responseFirst.json()
            setWencor(response) 
        } 
        catch(e) {
            console.log("WENCOR Parsing Error", e)
            setWencor([])
        }       
        setLoaderWENCOR(false)
    }

    return (
        <>
            <div className="Quote__results__container">
                <div className="Quote__results__container__header">

                    {/* Input */}
                    {wencor.length ? <p className="bold">{query}</p> : <></>}
                    <div>
                        <TextField
                            style={{width: "auto"}}   
                            variant="outlined"
                            label="Part Number"
                            required
                            onChange = {event => {setQuery(event.target.value)}}
                        />
                        <Button 
                            disabled={loaderWENCOR}
                            size="small" 
                            shape="round"
                            onClick={() => {getWencor(query)}}
                        >
                            WENCOR
                        </Button>
                        <Loader
                            type="Grid"
                            color="#508FCD"
                            height={50}
                            width={50}
                            visible={loaderWENCOR}
                        />
                    </div>
                </div>

                {/* Results */}
                <div>
                    {wencor.map((item:any) => !wencor[0].error ? (
                        <div>
                            <p style={{fontWeight: "bold"}}>{item.partNumber}</p>
                            <p>{item.description}</p>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-price"
                                    icon={<AttachMoney fontSize="small"/>}
                                    label={`Unit Price: ${item.price}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-stock"
                                    icon={<GridOnIcon fontSize="small"/>}
                                    label={`Quantity: ${item.stock}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <p style={{fontStyle: "italic"}}><span>Supplier: </span>Not Specified</p>
                            <p style={{fontStyle: "italic"}}><span>Warehouse: </span>Not Specified</p>
                            <p style={{fontStyle: "italic"}}><span>Manufacturer: </span>Not Specified</p>
                            <p><span style={{fontWeight: 600}}>Alternate Parts: </span></p>
                            {item.alt.map((altItem:string) => (
                                <div className="Quote__results__container__stocks__container override-height">
                                    <p style={customStyle_empty(altItem)}>{altItem}</p>
                                </div>
                            ))}
                            <hr/>
                        </div>
                    ) 
                        :
                    (
                        <>
                            {wencor[0].error === "None" && <p>No Results Found</p>}
                            {wencor[0].error === "Networkidle" && <p>Network Timeout. Please Try Again</p>}
                        </>
                    ))}
                </div>                           
            </div>
            <hr className="mainHR"/>
        </>
    )
}