import "../Quote/Quote.css"
import { useState } from "react"
import { QuoteInfoCustom } from "../../Constants/types"
import { customStyle_empty, formatAPIParams } from "../../Constants/functions"
import { 
    AttachMoney, 
    GridOnIcon, 
    Button,
    TextField,
    Chip,
    Loader,
    useMediaQuery,
} from "../../Constants/libraries"

export const Bd = () => {
    const phone = useMediaQuery({ query: "(max-width: 400px)"})
    const [loaderBD, setLoaderBD] = useState<boolean>(false) 
    const [bd, setBd] = useState<QuoteInfoCustom[] | []>([])
    const [query, setQuery] = useState<string>("")
    const [quantity, setQuantity] = useState<string>("")

    async function getBd(query:string, quantity:string) {
        
        try {
            //Initialize API
            setLoaderBD(true)
            let apiParams = formatAPIParams(query, quantity)
            const responseFirst = await fetch(`https://aerotrendsaviationbackend.com/bd/${apiParams[0]}/${apiParams[1]}/`)
            let response = await responseFirst.json()
            setBd(response)
        }
        catch(e) {
            console.log("BD Parsing Error", e)
            setBd([]) 
        }
        setLoaderBD(false)
    }

    return (
        <>
            <div className="Quote__results__container">
                <div className="Quote__results__container__header">

                    {/* Input */}
                    {bd.length ? <p className="bold">{query}</p> : <></>}
                    <div>
                        <TextField
                            style={{width: "auto"}}   
                            variant="outlined"
                            label="Part Number"
                            required
                            onChange = {event => {setQuery(event.target.value)}}
                        />
                        <TextField
                            style={{width: "200px"}}
                            variant="outlined"
                            label="Quantity"
                            margin="dense"
                            error={!/^[0-9]*$/.test(quantity)}
                            onChange = {event => {setQuantity(event.target.value)}}
                            helperText={!/^[0-9]*$/.test(quantity) ? "Not an Integer" : ""}
                        />
                        <Button 
                            disabled={loaderBD}
                            size="small" 
                            shape="round"
                            onClick={() => {getBd(query, quantity)}}
                        >
                            BOEING DSTR
                        </Button>
                        <Loader
                            type="Grid"
                            color="#508FCD"
                            height={50}
                            width={50}
                            visible={loaderBD}
                        />
                    </div>
                </div>

                {/* Results */}
                <div>
                    {bd.map((item:any) =>  !bd[0].error ? (
                        <div>
                            <p style={{fontWeight: "bold"}}>{item.partNumber}</p>
                            <p>{item.description}</p>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-price"
                                    icon={<AttachMoney fontSize="small"/>}
                                    label={`Unit Price: ${item.price}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-stock"
                                    icon={<GridOnIcon fontSize="small"/>}
                                    label={`Quantity: ${item.stock}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <p style={customStyle_empty(item.supplier)}><span>Supplier: </span>{item.supplier}</p>
                            <p style={customStyle_empty(item.warehouse)}><span>Warehouse: </span>{item.warehouse}</p>
                            <p style={customStyle_empty(item.manufacturer)}><span>Manufacturer: </span>{item.manufacturer}</p>
                            <p><span style={{fontWeight: 600}}>Alternate Parts: </span></p>
                            {item.alt.map((altItem:string) => (
                                <div className="Quote__results__container__stocks__container override-height">
                                    <p style={customStyle_empty(altItem)}>{altItem}</p>
                                </div>
                            ))}
                            <hr/>
                        </div>
                    )
                        :
                    (
                        <>
                            {bd[0].error === "None" && <p>No Results Found</p>}
                            {bd[0].error === "Networkidle" && <p>Network Timeout. Please Try Again</p>}
                        </>
                    ))}
                </div>
            </div>
            <hr className="mainHR"/>
        </>
    )
}