import "../Quote/Quote.css"
import Loader from "react-loader-spinner";
import { Button } from 'antd';
import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { formatAPIParams } from "../../Constants/functions";

type Props = {
    className?: string;
}

export const Aviall: React.FC<Props> = (props) => {
    const [loaderAVIALL, setLoaderAVIALL] = useState(false)
    const [aviall, setAviall] = useState<any[]>([]);
    const [query, setQuery] = useState(""); 

    async function getAviall(query:string) {

        //Error handling
        let apiParams = formatAPIParams(query)

        setLoaderAVIALL(true)
        try {
            const responseFirst = await fetch(`https://aerotrendsaviationbackend.com/aviall/${apiParams[0]}/`)
            let response = await responseFirst.json()
            // //Replace string & add props
            // !(response[0] === "None" || response[0] === "Networkidle" || response[0] === "CaptchaError") && response.map((item:any) => {
            //     item.partNumber = item.partNumber.replace(/  +/g, ' ')
            //     let trimIndex:number = item.partNumber.indexOf("\n")
            //     item.partNumber = item.partNumber.slice(0,trimIndex - 1)
            //     item.description = item.description.replace(/  +/g, ' ')
            //     item.price = item.price.replace('\n', ' >> ')
            //     item.price = item.price.replace('$', '')
            //     return item.website = "DASI"
            // })   
            setAviall(response)
        }
        catch {
            setAviall(["Networkidle"])
        }
        setLoaderAVIALL(false)
    }

    return (
        <>
            <div className="Quote__results__container">
                <div className="Quote__results__container__header">
                    <div>
                        {aviall[0] && <p className="bold">{aviall[0].partNumber}</p>}
                        {aviall[0] && <p className="bold_2">{aviall[0].description}</p>}
                    </div>
                    <div>
                        <TextField
                            style={{width: "auto"}}   
                            variant="outlined"
                            label="Part Number"
                            required
                            onChange = {event => {setQuery(event.target.value)}}
                        />
                        <Button 
                            disabled
                            size="small" 
                            shape="round"
                            onClick={() => {getAviall(query)}}
                        >
                            AVIALL
                        </Button>
                        <Loader
                            type="Grid"
                            color="#508FCD"
                            height={50}
                            width={50}
                            visible={loaderAVIALL}
                        />
                    </div>
                </div>
                {/* <div>
                    {!(aviall[0] === "None" || aviall[0] === "Networkidle") && aviall.map((item:any) => (
                        <div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-price"
                                    icon={<AttachMoney fontSize="small"/>}
                                    label={`Unit Price: ${item.price}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-stock"
                                    icon={<GridOnIcon fontSize="small"/>}
                                    label={`Quantity: ${item.stock}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <p style={{fontWeight: 400}}><span>Part Number: </span>{item.partNumber}</p>
                            <p style={{fontWeight: 400}}><span>Description: </span>{item.description}</p>
                            <p style={item.supplier === "Not Specified" ? {fontStyle: "italic"} : {fontStyle: "normal"}}><span>Supplier: </span>{item.supplier}</p>
                            <p style={item.warehouse === "Not Specified" ? {fontStyle: "italic"} : {fontStyle: "normal"}}><span>Warehouse: </span>{item.warehouse}</p>
                            <p style={item.manufacturer === "Not Specified" ? {fontStyle: "italic"} : {fontStyle: "normal"}}><span>Manufacturer: </span>{item.manufacturer}</p>
                            <p style={item.alt === "None" ? {fontStyle: "italic"} : {fontStyle: "normal"}}><span>Alternate: </span>{item.alt}</p>
                            <hr/>
                        </div>
                    ))}
                    {aviall[0] === "None" && <p>No Results Found</p>}
                    {aviall[0] === "Networkidle" && <p>Network Timeout. Please Try Again</p>}
                </div> */}
            </div>
            <hr className="mainHR"/>
        </>
    )
}