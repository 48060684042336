import Cookies from "universal-cookie"

//Validate session
export const validateSession = async ()  => {

    //Check if cookie exists
    const cookies = new Cookies()
    if (cookies.get("session_username")) {
        const session = {
            username: cookies.get("session_username"),
            token: cookies.get("session_token"),
            shortname: cookies.get("session_shortname")
        }

        try {
            //Check if token matches backend token
            const valid_session = await fetch("https://aerotrendsaviationbackend.com/login/validate_session", {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                method: 'post',
                body: JSON.stringify(session)
            }).then(data => data.json())

            //Response
            return valid_session[0] === "ValidSession" ? session.shortname : ""
        }
        catch(e) {
            console.log("Validating Previous Session Error", e)
            return ""
        }
    }

    //No cookies, must login instead
    else {
        return ""
    }
}


//Sort
export const customSort = (data:any[], identifier?:(string | number), descending?:boolean, parse?:boolean) => {
    if (identifier) {
        //If array of objects - String
        if (!parse) {
            data.sort((a, b) => {
                return descending ? 
                    (a[identifier].toLowerCase() < b[identifier].toLowerCase() ? 1 : -1)
                :
                    (a[identifier].toLowerCase() < b[identifier].toLowerCase() ? -1 : 1)
            })
        }

        //If array of objects - Number
        else {
            data.sort((a, b) => {
                //Parse
                a = parseInt(a[identifier])
                b = parseInt(b[identifier])
                return descending ? b - a : a - b
            })
        }
    }

    else if (typeof data[0] === "string") descending ? data.sort().reverse() : data.sort()
    else if (typeof data[0] === "number") data.sort((a, b) => descending ? b - a : a - b)
    
    return data
}

//Function to error handle API parameters
export function formatAPIParams(query:string, quantity?:string) {

    //Query
    if (query === "") {
        query = "S30661-011"
    }
    else {
        query = query.includes('/') ? query.replace(/\//g, '%2F') : query
        query = query.includes(' ') ? query.replace(/ /g, '%20') : query
    }

    //Quantity
    if (quantity === "") {
        quantity = "1";
    }
    else {
        quantity = quantity && quantity.includes('/') ? quantity.replace(/\//g, '%2F') : quantity
        quantity = quantity && quantity.includes(' ') ? quantity.replace(/ /g, '%20') : quantity
    }

    //Return as array
    query = query.trim()
    quantity = quantity && quantity.trim()
    return [query, quantity]
}

//Function to error handle important inputs
export function formatInputs(data:string, type:string, extra?:string) {
    const additionalString = extra ? extra : ""
    if (type === "email") {
        return data.endsWith("@aerotrends-aviation.com") ? "" : additionalString + "Email / username must end with @aerotrends-aviation.com"
    }
    else if (type === "password") {
        return data !== "" ? "" : additionalString + "Field cannot be empty"
    }
    else if (type === "general") {
        return data !== "" ? "" : additionalString + "Field cannot be empty"
    }
    else return ""
}

//Maps
export const urls = new Map()
urls.set("ACINV", "acinv/")
urls.set("WENCOR", "wencor/")
urls.set("DASI", "dasi/")
urls.set("PROPONENT", "proponent/")
urls.set("BD", "bd/")
urls.set("SATAIR", "satair/")


//Styles
export const customStyle_empty = (value:string) => {
    const fontStyle = (value === "Not Specified" || value === "None") ? "italic" : "normal" 
    return {fontStyle: fontStyle}
}
export const customStyle_empty_all = (value:string, extra?:any[]) => {
    let style:any = (value === "Not Specified" || value === "None") ? {
        fontStyle: "italic",
        fontSize: "11px"
    } : {
        fontStyle: "normal",
        fontSize: "12px" 
    }
    return style
}
