import { About } from "../../Components/About/About";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Footer } from "../../Components/Footer/Footer";

type Props = {
    className?: string;
}

export const about: React.FC<Props> = (props) => {

    return (
        <>
            <Navbar animation/>
            <About/>
            <Footer/>
        </> 
    )
}