import "./services.css";
import { Services } from "../../Components/Services/Services";
import { Navbar } from "../../Components/Navbar/Navbar";
import { Footer } from "../../Components/Footer/Footer";

type Props = {
    className?: string;
}

export const services: React.FC<Props> = (props) => {
    
    return (
        <>
            <Navbar animation/>
            <Services/>
            <Footer/>
        </> 
    )
}