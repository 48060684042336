/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars*/

import { useContext, useEffect, useState } from "react"
import { Context_Username } from "../../Constants/contexts"
import { validateSession } from "../../Constants/functions"
import { Redirect } from "../../Constants/libraries"
import "./Parser.css"

export const Parser = () => {

    const [redirect, setRedirect] = useState(false)
    const [username, setUsername] = useContext(Context_Username)

    useEffect(() => {
        const authenticate = async () => {
            const session = await validateSession()
            if (session) {
                setUsername(session) 
                setRedirect(false)
            }
            else {
                setUsername("")
                setRedirect(true)
            }
        }
        authenticate()
    }, [])

    return (
        <div className="Parser">
            {redirect ? <Redirect to="/login"/> : <></>}
            Otw
        </div>
    )
}