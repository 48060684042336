import "./homepage.css";
import { Homepage } from "../../Components/Homepage/Homepage";
import { Navbar } from "../../Components/Navbar/Navbar";

type Props = {
    className?: string;
}

export const homepage: React.FC<Props> = (props) => {

    return (
        <>
            <Navbar/>
            <Homepage/>
        </>
    )
}