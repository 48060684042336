import "../Quote/Quote.css"
import { useState } from "react"
import { QuoteInfoACINV } from "../../Constants/types"
import { formatAPIParams, customStyle_empty } from "../../Constants/functions"
import { 
    AttachMoney, 
    GridOnIcon, 
    Button,
    TextField,
    Chip,
    Loader,
    useMediaQuery,
} from "../../Constants/libraries"

export const Acinv = () => {

    const phone = useMediaQuery({ query: "(max-width: 400px)"})
    const [loaderACINV, setLoaderACINV] = useState<boolean>(false)
    const [acinv, setAcinv] = useState<QuoteInfoACINV[] | []>([])
    const [query, setQuery] = useState<string>("")

    async function getAcinv(query:string) {
        try { 
            //Initialize API
            setLoaderACINV(true)
            let apiParams = formatAPIParams(query)
            const responseFirst = await fetch(`https://aerotrendsaviationbackend.com/acinv/${apiParams[0]}/`)
            let response = await responseFirst.json();
            setAcinv(response)
        }

        catch(e) {
            console.log("ACINV Parsing Error", e)
            setAcinv([])
        }
        setLoaderACINV(false)
    }

    return (
        <>
            <div className="Quote__results__container">
                <div className="Quote__results__container__header">

                    {/* Input */}
                    {acinv.length ? <p className="bold">{query}</p> : <></> }
                    <div>
                        <TextField
                            style={{width: "auto"}}   
                            variant="outlined"
                            label="Part Number"
                            margin="dense"
                            required
                            onChange = {event => {setQuery(event.target.value)}}
                        />
                        <Button 
                            disabled={loaderACINV}
                            size="small" 
                            shape="round"
                            onClick={() => {getAcinv(query)}}
                        >
                            ACINV
                        </Button>
                        <Loader
                            type="Grid"
                            color="#508FCD"
                            height={50}
                            width={50}
                            visible={loaderACINV}
                        />
                    </div>
                </div>
                
                {/* Results */}
                <div>
                    {acinv.map((item:any) => !acinv[0].error ? ( 
                        <div>
                            <p style={{fontWeight: "bold"}}>{item.partNumber}</p>
                            <p>{item.description}</p>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-price"
                                    icon={<AttachMoney fontSize="small"/>}
                                    label={`Unit Price: ${item.price}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-stock"
                                    icon={<GridOnIcon fontSize="small"/>}
                                    label={`Quantity: ${item.stock}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <p><span>Supplier: </span>{item.supplier}</p>
                            <p style={customStyle_empty(item.warehouse)}><span>Warehouse: </span>{item.warehouse}</p>
                            <p style={customStyle_empty(item.manufacturer)}><span>Manufacturer: </span>{item.manufacturer}</p>
                            <p style={customStyle_empty(item.alt)}><span>Alternate: </span>{item.alt}</p>
                            <hr/>
                        </div>
                    )
                        :
                    (
                        <>
                            {acinv[0].error === "None" && <p>No Results Found</p>}
                            {acinv[0].error === "Networkidle" && <p>Network Timeout. Please Try Again</p>}
                        </>
                    ))}
                </div>
            </div>
            <hr className="mainHR"/>
        </>
    )
}
