import "../Quote/Quote.css"
import { useState } from "react"
import { QuoteInfoSATAIR } from "../../Constants/types"
import { formatAPIParams, customStyle_empty } from "../../Constants/functions"
import { 
    AttachMoney, 
    GridOnIcon, 
    ArticleIcon,
    Button,
    TextField,
    Chip,
    Loader,
    useMediaQuery,
} from "../../Constants/libraries"

export const Satair = () => {
    const phone = useMediaQuery({ query: "(max-width: 400px)"})
    const [loaderSATAIR, setLoaderSATAIR] = useState<boolean>(false)
    const [satair, setSatair] = useState<QuoteInfoSATAIR[] | []>([])
    const [query, setQuery] = useState<string>("")

    async function getSatair(query:string) {

        try {
            //Initialize API
            setLoaderSATAIR(true)
            let apiParams = formatAPIParams(query)
            const responseFirst = await fetch(`https://aerotrendsaviationbackend.com/satair/${apiParams[0]}/`)
            let response = await responseFirst.json()
            setSatair(response)
        }
        catch(e) {
            console.log("SATAIR Parsing Error", e)
            setSatair([])
        }
        setLoaderSATAIR(false)
    }

    return (
        <>
            <div className="Quote__results__container">
                <div className="Quote__results__container__header">

                    {/* Input */}
                    {satair.length ? <p className="bold">{query}</p> : <></>}
                    <div>
                        <TextField
                            style={{width: "auto"}}   
                            variant="outlined"
                            label="Part Number"
                            required
                            onChange = {event => {setQuery(event.target.value)}}
                        />
                        <Button 
                            disabled={loaderSATAIR}
                            size="small" 
                            shape="round"
                            onClick={() => {getSatair(query)}}
                        >
                            SATAIR
                        </Button>
                        <Loader
                            type="Grid"
                            color="#508FCD"
                            height={50}
                            width={50}
                            visible={loaderSATAIR}
                        />
                    </div>
                </div>

                {/* Results */}
                <div>
                    {satair.map((item) => !satair[0].error ? (
                        <div>
                            <p style={{fontWeight: "bold"}}>{item.partNumber}</p>
                            <p>{item.description}</p>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-price"
                                    icon={<AttachMoney fontSize="small"/>}
                                    label={`Unit Price: ${item.price}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-stock"
                                    icon={<GridOnIcon fontSize="small"/>}
                                    label={`Quantity: ${item.stock}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-condition"
                                    icon={<ArticleIcon fontSize="small"/>}
                                    label={`Certificate: ${item.certNotes}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <p style={customStyle_empty(item.supplier)}><span>Supplier: </span>{item.supplier}</p>
                            <p style={customStyle_empty(item.warehouse)}><span>Warehouse: </span>{item.warehouse}</p>
                            <p style={customStyle_empty(item.manufacturer)}><span>Manufacturer: </span>{item.manufacturer}</p>
                            <p style={customStyle_empty(item.alt)}><span>Alternate: </span>{item.alt}</p>
                            <hr/>
                        </div>
                    )
                        :
                    (
                        <>
                        {satair[0].error === "None" && <p>No Results Found</p>}
                        {satair[0].error === "Networkidle" && <p>Network Timeout. Please Try Again</p>}
                        </>
                    ))}
                </div>
            </div>
            <hr className="mainHR"/>
        </>
    )
}