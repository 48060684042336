import "./About.css"
import {
    Helmet,
    HashLink,
    ExpandLessIcon,
    Button,
    Link,
    useMediaQuery,
} from "../../Constants/libraries"
import { 
        mStatement, 
        vStatement, 
        cStatement, 
        descriptionMsia, 
        descriptionUs, 
        url_cert_logo,
        url_cert_logo_alt,
        cert_download,
        clientsHeader, 
        header_first_span,
        name_usa,
        name_msia,
        header_first
} from "../../Constants/texts"

export const About = () => {

    const tablet = useMediaQuery({ query: "(max-width: 770px)"});
    const url_cert_bg = "https://aerotrendsaviation-videobucket.s3.amazonaws.com/about/cert-background.jpg";

    return (   
        <div id="top" className="About"
        style={{
            background: "#000000",
            backgroundImage: `url(${url_cert_bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundAttachment: "fixed"
        }}
        >

            <Helmet>
                <title>About | Aerotrends Aviation</title>
            </Helmet>

            {/* First background */}
            <div 
                style={{
                    background: "#000000",
                    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${url_cert_bg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed"
                }}
                className="About__background-first"
            >
                <p>WHO WE ARE</p>
                <div>
                    <a href="#about__header">About Us</a>
                    <a href="#about__statements">Mission / Vision Statement</a>
                    <a href="#about__commitment">ASA</a>
                </div>
            </div>
            
            <div className="fixed-background">
                <div className="About__header" id="about__header">   
                    <p>{header_first.slice(0, 55)}<span>{header_first_span}</span>{header_first.slice(85, header_first.length)}</p>
                </div>

                {/* Office */}
                {!tablet ?
                    <div className="About__background-second"> 
                        <div className="About__background-second__wrapper">
                            <div className="About__background-second__wrapper__section">
                                <div></div>
                                <figure className="imghvr-shutter-out-diag-1 about-figure">
                                    <p>{name_msia}</p>
                                    <figcaption className="about-figcaption">
                                        <p>{descriptionMsia}</p>   
                                    </figcaption>
                                </figure>
                            </div>

                            <div className="About__cStatement">
                                <p>{clientsHeader}</p>
                            </div>   

                            <div className="About__background-second__wrapper__section">
                                <div></div>
                                <figure className="imghvr-shutter-out-diag-1 about-figure">
                                    <p>{name_usa}</p>
                                    <figcaption className="about-figcaption">
                                        <p>{descriptionUs}</p>
                                    </figcaption>
                                </figure> 
                            </div>
                        </div>
                    </div>
                :
                    <div className="About__background-second"> 
                        <div className="About__background-second__wrapper">
                            <div className="About__background-second__wrapper__section">
                                <div><p style={{opacity: 0}}>a</p></div>
                                <p>{name_msia}</p>
                                <p>{descriptionMsia}</p>   
                            </div>

                            <div className="About__background-second__wrapper__section">
                                <div><p style={{opacity: 0}}>a</p></div>
                                <p>{name_usa}</p>
                                <p>{descriptionUs}</p> 
                            </div>

                            <div className="About__cStatement">
                                <p>{clientsHeader}</p>
                            </div>   
                        </div>
                    </div>
                }


                {/* Statements */}
                <div className="About__statements" id="about__statements">
                    <div className="About__statements__section">
                        <p>Mission Statement</p>
                        <p>{mStatement}</p>
                    </div>
                    <div className="About__statements__section">
                        <p>Vision Statement</p>
                        <p>{vStatement}</p>
                    </div>
                </div>
            </div>

            {/* Commitment */}
            <div id="about__commitment" className="About__commitment">
                <div className="About__commitment__title-logo">
                    <p>OUR COMMITMENT</p>
                    <a href={cert_download} target="_blank" rel="noreferrer">
                        <img className="cert" 
                            src={!tablet ? url_cert_logo : url_cert_logo_alt} 
                            alt="ASA-Cert" 
                        />
                    </a>
                </div>

                <div className="About__commitment__text">
                    <p>{cStatement}</p>
                    <p className="line"></p>
                    <Button shape="round" type="default"><HashLink to="/services#top">SERVICES</HashLink></Button>
                </div>

                <Link 
                    className="about-scroll-up" 
                    to="top" 
                    spy={true} 
                    smooth={true}
                >
                    <ExpandLessIcon fontSize={tablet ? "default" : "large"}/>
                </Link>
            </div>

            {tablet && 
                <div className="About__commitment__text-mobile">
                    <p>{cStatement}</p>
                    <p className="line"></p>
                    <Button shape="round" type="default"><HashLink to="/services#top">SERVICES</HashLink></Button>
                </div>                          
            }
        </div>
    )
}