import { Navbar } from "../../Components/Navbar/Navbar"
import { Footer } from "../../Components/Footer/Footer"
import { Login } from "../../Components/Login/Login"

type Props = {
    className?: string;
}

export const login: React.FC<Props> = (props) => {

    return (
        <>
            <Navbar contrast/>
            <Login/>
            <Footer/>
        </> 
    )
}