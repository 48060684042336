import "./Services.css";
import { Helmet } from "react-helmet";
import {
    serviceDescription,
    serviceTypes,
    serviceContents,
    clients,
    empty_divs
} from "../../Constants/texts";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HashLink } from "react-router-hash-link";
import { Button } from "antd";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useMediaQuery } from "react-responsive";
import { Link } from "react-scroll";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

type Props = {
    className?: string;
}

export const Services: React.FC<Props> = (props) => {

    const tablet = useMediaQuery({ query: "(max-width: 770px)"});
    const url_expertise = "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/services-expertise.jpg";
    const url_fixed_background = "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/services-fixed-background.jpg";
    
    return (
        <div 
            id="top" 
            className="Services"
            style={{
                background: "#000000",
                backgroundImage: `url(${url_fixed_background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundAttachment: "fixed"
            }} 
        >

            <Helmet>
                <title>Services | Aerotrends Aviation</title>
            </Helmet>

            <div 
                style={{
                    background: "#000000",
                    backgroundImage: `url(${url_expertise})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }} 
                className="Services__description"
            >
                    {empty_divs}
                    <p>SUPPORTED BY PROFESSIONAL EXPERTISE</p>
                <Link 
                    className="services-scroll" 
                    to="services-timeline" 
                    spy={true} 
                    smooth={true}
                >
                    <ExpandMoreIcon 
                        fontSize={tablet ? "default" : "large"}
                        style={{color: "white"}} 
                    />
                </Link>
            </div>

            <div id="services-timeline" className="Services__timeline">
                <VerticalTimeline className="custom-line">
                    {serviceContents.map((serviceContent) => (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work Services__timeline-element"
                            iconStyle={{ opacity: 1, color: "#cccccc"}}
                            icon={<serviceContent.icon/>}
                            date={serviceContent.title}
                            dateClassName="Services__timeline-title"
                        >
                            <p className="Services__timeline-text">{serviceContent.content}</p>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
                <Link 
                    className="services-scroll" 
                    to="services-second" 
                    spy={true} 
                    smooth={true}
                >
                    <ExpandMoreIcon fontSize={tablet ? "default" : "large"}/>
                </Link>
            </div>

            {!tablet ?
                <div id="services-second" className="Services__first">
                    <div className="Services__first__half">
                        <p>SERVICES</p>
                        <figure className="imghvr-reveal-left">
                            <figcaption>
                            {serviceTypes.map((serviceType) => (
                                <p>
                                    <serviceType.icon fontSize="small" className="firstNone"/>
                                    <span>{serviceType.text}</span>
                                </p>
                            ))}
                            </figcaption>
                        </figure>
                        {tablet &&
                            <Link 
                                className="services-scroll" 
                                to="services-second-p2" 
                                spy={true} 
                                smooth={true}
                            >
                                <ExpandMoreIcon style={{color: "white"}}/>
                            </Link>             
                        }
                    </div>

                    <div id="services-second-p2" className="Services__first__half">
                        <p>CLIENT BASE</p>
                        <figure className="imghvr-reveal-right">
                            <figcaption>
                            {clients.map((client) => (
                                <p>
                                    <client.logo fontSize="small" className="firstNone"/>
                                    <span>{client.text}</span>
                                </p>
                            ))}
                            </figcaption>
                        </figure>
                        {tablet &&
                            <Link 
                                className="services-scroll" 
                                to="services-timeline" 
                                spy={true} 
                                smooth={true}
                            >
                                <ExpandMoreIcon style={{color: "white"}}/>
                            </Link>             
                        }
                    </div>

                    {!tablet &&
                        <Link 
                            className="services-scroll" 
                            to="services-gallery" 
                            spy={true} 
                            smooth={true}
                        >
                            <ExpandMoreIcon fontSize={tablet ? "default" : "large"}/>
                        </Link>             
                    }
                </div>
            :
                <div id="services-second" className="Services__first-mobile">
                    <div className="Services__first__half-mobile">
                        <div className="Services__first__half__figcaption">
                            {serviceTypes.map((serviceType) => (
                                <p>
                                    <serviceType.icon fontSize="small" className="firstNone"/>
                                    <span>{serviceType.text}</span>
                                </p>
                            ))}
                        </div>

                        {tablet &&
                            <Link 
                                className="services-scroll" 
                                to="services-second-p2" 
                                spy={true} 
                                smooth={true}
                            >
                                <ExpandMoreIcon style={{color: "white"}}/>
                            </Link>             
                        }
                    </div>

                    <div id="services-second-p2" className="Services__first__half-mobile">
                        <div className="Services__first__half__figcaption extraPadding">
                            {clients.map((client) => (
                                <p>
                                    <client.logo fontSize="small" className="firstNone"/>
                                    <span>{client.text}</span>
                                </p>
                            ))}
                        </div>
                        {tablet &&
                            <Link 
                                className="services-scroll" 
                                to="services-gallery" 
                                spy={true} 
                                smooth={true}
                            >
                                <ExpandMoreIcon style={{color: "white"}}/>
                            </Link>             
                        }
                    </div>

                    {!tablet &&
                        <Link 
                            className="services-scroll" 
                            to="services-gallery" 
                            spy={true} 
                            smooth={true}
                        >
                            <ExpandMoreIcon fontSize={tablet ? "default" : "large"}/>
                        </Link>             
                    }
                </div>   
            }

            <div id="services-gallery" className="Services__gallery">
                {/* <Gallery 
                    photos={photos_first}
                    direction="row"
                    margin={tablet ? 1 : 2}
                /> */}
                <div className="Services__last">
                    <p>{serviceDescription}</p>
                    <Button shape="round" type="default"><HashLink to="/contact#top">CONTACT</HashLink></Button>
                    <Link 
                        className="services-scroll-up" 
                        to="top" 
                        spy={true} 
                        smooth={true}
                    >
                        <ExpandLessIcon fontSize={tablet ? "default" : "large"}/>
                    </Link>
                </div>
                {/* <Gallery 
                    photos={photos_second}
                    direction="row"
                    margin={tablet ? 1 : 2}
                /> */}
            </div>


        </div>
    )
}