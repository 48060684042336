import { Navbar } from "../../Components/Navbar/Navbar"
import { Footer } from "../../Components/Footer/Footer"
import { Parser } from "../../Components/Parser/Parser"

type Props = {
    className?: string;
}

export const parser: React.FC<Props> = (props) => {

    return (
        <>
            <Navbar contrast/>
            <Parser/>
            <Footer/>
        </> 
    )
}