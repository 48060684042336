import "./Footer.css"
import {
    emailMsia,
    emailUsa,
    phoneMsia,
    phoneUsa
} from "../../Constants/texts"
import { HashLink } from "../../Constants/libraries"

export const Footer = () => {
    return (
        <div className="Footer">
            <p className="noHover">Copyright © 2021 Aerotrends Aviation</p>
            <p>
                <HashLink to="/privacy-policy#top">Privacy Policy</HashLink>
            </p>
            <div>
                <p><a href={`mailto:${emailMsia}`}>{emailMsia}</a></p>
                <p><a href={`mailto:${emailUsa}`}>{emailUsa}</a></p>
                <p><a href={`tel:${phoneMsia}`}>{phoneMsia}</a> (Malaysia)</p>
                <p><a href={`tel:${phoneUsa}`}>{phoneUsa}</a> (USA)</p>
            </div>
        </div>
    )
}