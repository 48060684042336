import SettingsIcon from '@material-ui/icons/Settings';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import BuildIcon from '@material-ui/icons/Build';
import FlightIcon from '@material-ui/icons/Flight';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import UpdateIcon from '@material-ui/icons/Update';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

//Homepage.tsx
export const vidUrl_webm = "https://aerotrendsaviation-videobucket.s3.amazonaws.com/homepage/plane.webm";
export const vidError = "Your browser does not support this video";
export const header_main = "LAUNCHED TO PERFECTION";
export const header_first = "Aerotrends Aviation is built around core principles of Honesty, Respect and Integrity, values that we live and grow by.";
export const header_first_span = "Honesty, Respect and Integrity";
export const header_second = "Aircraft Parts, Supplies, Support & Consultation.";

//Quotes
export const website_wencor = "https://fly.wencor.com/en/log-in"

//About.tsx
export const name_usa = "Aerotrends Aviation LLC"
export const name_msia = "Aerotrends Aviation Sdn Bhd"
export const mStatement = "To be a market leader in the global aftermarket aircraft parts supply industry by developing partnerships with our customers and vendors that are based on trust achieved through our performance which strives always to deliver consistently excellent service, reliability and timeliness.";
export const vStatement = "To be the most trusted and dependable solutions provider in the aircraft components aftermarket industry. To satisfy our customers’ needs through personalized services through our commitment to quality, safety and timeliness delivered with integrity.";
export const cStatement = "As an accredited member of the Aviation Suppliers Association (ASA), we incorporate ASA-100/FAA AC 00-56 QA systems into our practices. This ensures that all products supplied have complete traceability of history including to relevant airlines, operators, repair stations and original equipment manufacturers, as appropriate. All parts that have been subject to service or overhaul are supplied with FAA 8130-3, EASA Form 1 or relevant certification issued by approved sources so that their integrity is fully vouchsafed. Our Malaysia and USA warehouse facilities are stocked with rotable and expendable components to swiftly serve customer’s procurement needs. ";
export const descriptionMsia = "Established in 2003 as a specialized aviation supplier for Boeing 737 and 747 parts in South East Asia. The company subsequently extended its presence in the aftermarket aircraft parts supply industry to include repair management services, and power by the hour services. We have also established ourselves in the Airbus market with having inventory for A320 and A330 series airplanes, which also extending our capabilities in the Boeing market to include the B757, B767 and B777 series airplanes.";
export const descriptionUs = "Formed in 2010 as a result of our continuous growth. The founding partners of the company’s operations in Malaysia and USA bring to the table a combined experience of close to 50 years in the aviation business. The establishment of Aerotrends Aviation LLC further strengthens our market presence and enhances our ability to source aviation parts in an aggressive market with competitive pricing and prompt turnaround time.";
export const clientsHeader = "Through its 24/7 round the clock support service in pursuit of out–of–the–box solutions we provide a unique approach to aftermarket aircraft parts supply that elevates the industry to a new level."; 
export const clients = [
    {
        "logo": LanguageIcon,
        "text": "CLIENT BASE"
    },
    {
        "logo": LanguageIcon,
        "text": "Domestic and International Aviation Operators"
    },
    {
        "logo": SettingsIcon,
        "text": "Aircraft Maintenance"
    },
    {
        "logo": BuildIcon,
        "text": "Repair and Overhaul (MRO)"
    },
    {
        "logo": AssignmentIcon,
        "text": "Aircraft Leasing Companies"
    },
    {
        "logo": FlightTakeoffIcon,
        "text": "Ministry of Defense"
    },
    {
        "logo": FlightIcon,
        "text": "Aerospace Suppliers"
    },
];

//Services.tsx
export const url_cert_logo = "https://aerotrendsaviation-videobucket.s3.amazonaws.com/about/cert_asa.png";    
export const url_cert_logo_alt = "https://aerotrendsaviation-videobucket.s3.amazonaws.com/about/cert_asa_alt.png";                      
export const cert_download = "https://aerotrendsaviation-videobucket.s3.amazonaws.com/about/ASA_Cert.pdf";
export const serviceDescription = "Our services are supported by aviation professional expertise and a strong network of resources that enable us to collaborate closely with customers to provide products and services that fully meet requirements.";
export const serviceTypes = [
    {
        "icon": SettingsIcon,
        "text": "SERVICES"
    },
    {
        "icon": SettingsIcon,
        "text": "Spares Sourcing & Procurement"
    },
    {
        "icon": WebAssetIcon,
        "text": "Asset Management"
    },
    {
        "icon": BuildIcon,
        "text": "Repair Administration"
    },
    {
        "icon": QueryBuilderIcon,
        "text": "24/7 Global AOG Support"
    },
    {
        "icon": FlightIcon,
        "text": "Engine & Aircraft Leasing"
    },
    {
        "icon": QuestionAnswerIcon,
        "text": "Consulting"
    },
];
export const serviceContents = [
    {
        "title": "Aftermarket Supply, Exchange & Leasing",
        "content": "We provide total supply solutions through our broad range of stocks held at both warehouses to help deliver cost savings and operational efficiency.",
        "icon": UpdateIcon
    },
    {
        "title": "Repairs Management",
        "content": "Management of component repairs is undertaken in both Malaysia and the USA utilising a panel of reputable service partners who share our commitment to quality.",
        "icon": BuildIcon
    },
    {
        "title": "Consignment",
        "content": "We offer consignment services that through our global network, effective marketing and secure storage, enables conversion of customer’s excess material, serviceable or unserviceable, into revenue.",
        "icon": CompareArrowsIcon
    },
    {
        "title": "Total Parts Support Program",
        "content": "We strive to go the extra mile and beyond parts supply to render support in the areas of logistics, consulting, asset planning, and research as part of our Total Parts Support Program.",
        "icon": AirplanemodeActiveIcon
    },
];
export const photos_first = [
    {
        src: "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_third.jpg",
        width: 2,
        height: 1.4
    },//vertical
    {
        src: "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_fifth.jpg",
        width: 2,
        height: 1.4
    },//cylinder
    {
        src: "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_first.jpg",
        width: 2,
        height: 1.4
    },//green
    {
        src: "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_fourth.jpg",
        width: 2,
        height: 1.4
    },//box
];
export const photos_second = [
    {
        src: "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery-ninth.jpg",
        width: 1.6,
        height: 1.4
    },//usaWarehouse
    {
        src: "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery-eight.jpg",
        width: 1.6,
        height: 1.4
    },//msiaWarehouse1
    {
        src: "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_seventh.jpg",
        width: 1.6,
        height: 1.4
    },//usaFront
    {
        src: "https://aerotrendsaviation-videobucket.s3.amazonaws.com/services/gallery_sixth.jpg",
        width: 1.6,
        height: 1.4
    },//msiaFront
];
export const empty_divs = <>
                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        </>

//Navbar.tsx
export const team_msia = [
    {
        "Owner": ["Philip Raju"],
        "Chief Financial Officer": ["Michelle Mooi Ying Han"]
    },
    {
        "Sales Manager": ["Prem Kumar A/L Ganasaratnam"],
        "Business Development Manager": ["Stephanie Koh Chien Kim"],
        "Management Associate": ["Carly Yeoh Ying Xuan"]
    },
    {
        "Sales Account Manager": ["Eddie Lau Kean Mun"]
    },
    {
        "Accounting Associate": ["Nor Hidayah Binti Ramlee", "Siah Chia Sin"]
    },
    {
        "Admin and Operations": ["Nazareth Adrina Dtrom", "Santhy A/P Rajen"]
    },
    {
        "Sales Associate": ["Aw Kar Liong, Jeffrey", "Ho Koon Song, Eric"]
    },
    {
        "Quality Assurance": ["Loo Xian Lun, Daniel"]
    },
    {
        "Purchase and Repair Coordinator": ["Ng Zi Yin"]
    },
    {
        "Transport Specialist": ["Vicknesvaran A/L Santhra Sakaran"]
    },
    {
        "Warehouse Associate": ["Shashitharan A/L Ishwaran"],
        "Warehouse Assistant": ["Sailesh A/L Sukumaran", "Haarindren Raj A/L Vijayan"]
    }
]

export const team_usa = [
    {
        "President": ["Donald J Ragsdale"]
    },
    {
        "VP Sales": ["Ruston Kirby"]
    },
    {
        "Quality Assurance": ["Jason A Ragsdale"]
    },
    {
        "Sales Account Manager": ["Sergio Rocha", "Chad M Hall"]
    },
    {
        "Sales Support": ["Mia Rawson"]
    },
    {
        "Warehouse Associate": ["Shane Ragsdale"]
    }
]

//Footer.tsx
export const emailMsia = "info@aerotrends-aviation.com";
export const emailUsa = "sales@aerotrends-aviation.com";
export const phoneMsia = "+603 5885 3888";
export const phoneUsa = "+1 817 490 1100";
export const faxMsia = "+603 5885 3886";
export const faxUsa = "+1 817 490 1109";
export const addUsa = "STE 150, 1200 Texan Trail, 76051 Grapevine, TX, USA";
export const addMsia = "5 Jalan Kartunis U1/47, Glenmarie Temasya 40150 Shah Alam, Selangor, Malaysia";

//Contact.tsx
export const contactInfoMsia = [
    {
        "logo": EmailIcon,
        "text": emailMsia,
        "href": "mailto:info@aerotrends-aviation.com"
    },
    {
        "logo": PhoneIcon,
        "text": phoneMsia,
        "href": "tel:+60-35-885-3888"
    },
    {
        "logo": PrintIcon,
        "text": faxMsia,
        "href": "tel:+60-35-885-3886"
    }
]
export const contactInfoUS = [
    {
        "logo": EmailIcon,
        "text": emailUsa,
        "href": "mailto:sales@aerotrends-aviation.com"
    },
    {
        "logo": PhoneIcon,
        "text": phoneUsa,
        "href": "tel:+1-817-490-1100"
    },
    {
        "logo": PrintIcon,
        "text": faxUsa,
        "href": "tel:+1-817-490-1109"
    }
]

//privacy-policy.tsx
export const privacyPolicyHeader = [
    "AEROTRENDS AVIATION operates the http://www.aerotrends-aviation.com website, which provides the SERVICE.",
    "This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the http://www.aerotrends-aviation.com website.",
    "If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy."
]
export const informationCollection = "For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.";
export const logData = "We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.";
export const cookies = [
    "Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.",
    "Our website uses these “cookies” to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service."
]
export const serviceProviders = [
    "We may employ third-party companies and individuals due to the following reasons:",
    "To facilitate our Service",
    "To provide the Service on our behalf",
    "To assist us in analyzing how our Service is used",
    "We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose."
]
export const security = "We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.";
export const otherSitesLinks = "Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.";
export const changesPP = "We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.";
