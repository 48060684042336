import "../Quote/Quote.css"
import { useState } from "react"
import { QuoteInfoPROPONENT } from "../../Constants/types"
import { customStyle_empty, formatAPIParams } from "../../Constants/functions"
import { 
    AttachMoney, 
    Button,
    TextField,
    Chip,
    Loader,
    useMediaQuery,
} from "../../Constants/libraries"

export const Proponent = () => {
    const phone = useMediaQuery({ query: "(max-width: 400px)"})
    const [loaderPROPONENT, setLoaderPROPONENT] = useState<boolean>(false)
    const [proponent, setProponent] = useState<QuoteInfoPROPONENT[] | []>([])
    const [query, setQuery] = useState<string>("")
    const [quantity, setQuantity] = useState<string>("")

    async function getProponent(query:string, quantity:string) {

            try {
                //Initialize API
                setLoaderPROPONENT(true)
                let apiParams = formatAPIParams(query, quantity)
                const responseFirst = await fetch(`https://aerotrendsaviationbackend.com/proponent/${apiParams[0]}/${apiParams[1]}/`)
                let response = await responseFirst.json()
                setProponent(response)    
            }
            catch (e) {
                console.log("Proponent Parsing Error", e)
                setProponent([])       
            }
            setLoaderPROPONENT(false)
        }

    return (
        <>
            <div className="Quote__results__container">
                <div className="Quote__results__container__header">

                    {/* Input */}
                    {proponent.length ? <p className="bold">{query}</p> : <></>}
                    <div>
                        <TextField
                            style={{width: "auto"}}   
                            variant="outlined"
                            label="Part Number"
                            required
                            onChange = {event => {setQuery(event.target.value)}}
                        />
                        <TextField
                            style={{width: "200px"}}
                            variant="outlined"
                            label="Quantity"
                            margin="dense"
                            error={!/^[0-9]*$/.test(quantity)}
                            onChange = {event => {setQuantity(event.target.value)}}
                            helperText={!/^[0-9]*$/.test(quantity) ? "Not an Integer" : ""}
                        />
                        <Button 
                            disabled={loaderPROPONENT}
                            size="small" 
                            shape="round"
                            onClick={() => {getProponent(query, quantity)}}
                        >
                            PROPONENT
                        </Button>
                        <Loader
                            type="Grid"
                            color="#508FCD"
                            height={50}
                            width={50}
                            visible={loaderPROPONENT}
                        />
                    </div>
                </div>

                {/* Results */}
                <div>
                    {proponent.map((item:any) => !proponent[0].error ? (
                        <div>
                            <p style={{fontWeight: "bold"}}>{item.partNumber}</p>
                            <p>{item.description}</p>
                            <div className="chip-container">
                                <Chip
                                    variant="outlined"
                                    className="chip-price"
                                    icon={<AttachMoney fontSize="small"/>}
                                    label={`Unit Price: ${item.price}`}
                                    style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}                                      
                                />
                            </div>
                            <p style={item.supplier === "Not Specified" ? {fontStyle: "italic"} : {fontStyle: "normal"}}><span>Supplier: </span>{item.supplier}</p>
                            <p><span style={{fontWeight: 600}}>Stock Info: </span></p>
                            <div className="Quote__results__container__stocks">
                                {item.stock.map((stockItem:any) => (
                                    <div>
                                        <p><span>Warehouse: </span>{stockItem.warehouseName}</p>
                                        <p><span>Quantity: </span>{stockItem.warehouseQuantity}</p>
                                    </div>
                                ))}
                            </div>
                            <p style={customStyle_empty(item.manufacturer)}><span>Manufacturer: </span>{item.manufacturer}</p>
                            <p style={customStyle_empty(item.alt)}><span>Alternate: </span>{item.alt}</p>
                            <hr/>
                        </div>
                    )
                        :
                    (
                        <>
                            {proponent[0].error === "None" && <p>No Results Found</p>}
                            {proponent[0].error === "Networkidle" && <p>Network Timeout. Please Try Again</p>}
                        </>
                    ))}
                </div>
            </div>
            <hr className="mainHR"/>
        </>
    )
}